define("ember-power-select/templates/components/power-select/search-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5TTVq9jZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"ul\"],[9,\"class\",\"ember-power-select-options\"],[9,\"role\",\"listbox\"],[7],[0,\"\\n  \"],[6,\"li\"],[9,\"class\",\"ember-power-select-option ember-power-select-option--search-message\"],[9,\"role\",\"option\"],[7],[0,\"\\n    \"],[1,[18,\"searchMessage\"],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/search-message.hbs"
    }
  });

  _exports.default = _default;
});