define('ember-cli-d3/utils/version', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.helper = helper;
  exports.computed = computed;

  var _Ember$VERSION$match$map = _ember['default'].VERSION.match(/^(\d+)\.(\d+)\.(\d+)/).map(Number);

  var _Ember$VERSION$match$map2 = _slicedToArray(_Ember$VERSION$match$map, 4);

  var MAJOR = _Ember$VERSION$match$map2[1];
  var MINOR = _Ember$VERSION$match$map2[2];
  var PATCH = _Ember$VERSION$match$map2[3];
  exports['default'] = { MAJOR: MAJOR, MINOR: MINOR, PATCH: PATCH };
  var hasGlimmer = MAJOR >= 2 || MINOR === 13;

  exports.hasGlimmer = hasGlimmer;
  var hasHTMLBars = MAJOR >= 2 || MINOR >= 10;

  exports.hasHTMLBars = hasHTMLBars;

  function helper(fn) {
    if (_ember['default'].Helper) {
      return _ember['default'].Helper.helper(fn);
    } else if (_ember['default'].HTMLBars) {
      return _ember['default'].HTMLBars.makeBoundHelper(fn);
    } else {
      return _ember['default'].Handlebars.makeBoundHelper(fn);
    }
  }

  function computed() {
    for (var _len = arguments.length, deps = Array(_len), _key = 0; _key < _len; _key++) {
      deps[_key] = arguments[_key];
    }

    var fn = deps.pop();
    var hasSetter = fn.length > 1;

    // old computed
    if (MAJOR === 1 && MINOR < 12) {
      return _ember['default'].computed.apply(_ember['default'], deps.concat([fn]));
    }

    // new computed
    return _ember['default'].computed.apply(_ember['default'], deps.concat([{
      get: function get() {
        return fn.apply(this, arguments);
      },

      set: !hasSetter ? null : function () {
        return fn.apply(this, arguments);
      }
    }]));
  }
});