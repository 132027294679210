define("ember-date-components/utils/should-use-am-pm", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shouldUseAmPm = shouldUseAmPm;
  _exports.default = void 0;

  function shouldUseAmPm() {
    return (0, _moment.default)().startOf('day').format('LLL').toLowerCase().indexOf('am') > -1;
  }

  var _default = shouldUseAmPm;
  _exports.default = _default;
});