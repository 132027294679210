define('ember-data-in-memory-adapter/index', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {

  var indexOf = Array.prototype.indexOf && function (array, item) {
    return array.indexOf(item);
  } || _ember['default'].EnumerableUtils.indexOf;

  exports['default'] = _emberData['default'].Adapter.extend({
    defaultSerializer: '-default',

    data: {},

    // Ember Data 2.0 reload behavior
    shouldReloadRecord: function shouldReloadRecord() {
      return false;
    },
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },

    // The fixture adapter does not support coalesceFindRequests
    coalesceFindRequests: false,

    /**
      If `simulateRemoteResponse` is `true` the `InMemoryAdapter` will
      wait a number of milliseconds before resolving promises with the
      fixture values. The wait time can be configured via the `latency`
      property.
      @property simulateRemoteResponse
      @type {Boolean}
      @default true
    */
    simulateRemoteResponse: true,

    /**
      By default the `InMemoryAdapter` will simulate a wait of the
      `latency` milliseconds before resolving promises with the fixture
      values. This behavior can be turned off via the
      `simulateRemoteResponse` property.
      @property latency
      @type {Number}
      @default 50
    */
    latency: 50,

    /**
      Implement this method in order to provide data associated with a type
      @method dataForType
      @param {Subclass of DS.Model} typeClass
      @return {Array}
    */
    dataForType: function dataForType(typeClass) {
      this.data[typeClass] = this.data[typeClass] || _ember['default'].A();
      return this.data[typeClass];
    },

    /**
      Implement this method in order to query in-memory data
      @method queryData
      @param {Array} data
      @param {Object} query
      @param {Subclass of DS.Model} typeClass
      @return {Promise|Array}
    */
    queryData: function queryData() /*fixtures, query, typeClass*/{
      _ember['default'].assert('Not implemented: You must override the DS.InMemoryAdapter::queryFixtures method to support querying the in-memory store.');
    },

    /**
      @method updateFixtures
      @param {Subclass of DS.Model} typeClass
      @param {Array} fixture
    */
    updateData: function updateData(typeClass, data) {
      var typeData = this.dataForType(typeClass);

      typeData.pushObjects(data);
    },

    /**
      Implement this method in order to provide json for CRUD methods
      @method mockJSON
      @param {DS.Store} store
      @param {Subclass of DS.Model} typeClass
      @param {DS.Snapshot} snapshot
    */
    mockJSON: function mockJSON(store, typeClass, snapshot) {
      return store.serializerFor(snapshot.modelName).serialize(snapshot, { includeId: true });
    },

    /**
      @method find
      @param {DS.Store} store
      @param {subclass of DS.Model} typeClass
      @param {String} id
      @param {DS.Snapshot} snapshot
      @return {Promise} promise
    */
    find: function find(store, typeClass, id /*, snapshot*/) {
      var data = this.dataForType(typeClass);
      var item = data.findBy('id', id);

      return this.simulateRemoteCall(function () {
        return item;
      });
    },

    /**
      @method findMany
      @param {DS.Store} store
      @param {subclass of DS.Model} typeClass
      @param {Array} ids
      @param {Array} snapshots
      @return {Promise} promise
    */
    findMany: function findMany(store, typeClass, ids /*, snapshots*/) {
      var data = this.dataForType(typeClass);
      var matches = data.filter(function (item) {
        return indexOf(ids, item.id) !== -1;
      });

      return this.simulateRemoteCall(function () {
        return matches;
      });
    },

    /**
      @private
      @method findAll
      @param {DS.Store} store
      @param {subclass of DS.Model} typeClass
      @param {String} sinceToken
      @return {Promise} promise
    */
    findAll: function findAll(store, typeClass) {
      var data = this.dataForType(typeClass);

      return this.simulateRemoteCall(function () {
        return data;
      });
    },

    /**
      @private
      @method findQuery
      @param {DS.Store} store
      @param {subclass of DS.Model} typeClass
      @param {Object} query
      @param {DS.AdapterPopulatedRecordArray} recordArray
      @return {Promise} promise
    */
    findQuery: function findQuery(store, typeClass, query /*, array*/) {
      var data = this.dataForType(typeClass);

      var matches = this.queryData(data, query, typeClass);

      return this.simulateRemoteCall(function () {
        return matches;
      });
    },

    /**
      @method createRecord
      @param {DS.Store} store
      @param {subclass of DS.Model} typeClass
      @param {DS.Snapshot} snapshot
      @return {Promise} promise
    */
    createRecord: function createRecord(store, typeClass, snapshot) {
      var data = this.mockJSON(store, typeClass, snapshot);

      this.updateData(typeClass, data);

      return this.simulateRemoteCall(function () {
        return data;
      });
    },

    /**
      @method updateRecord
      @param {DS.Store} store
      @param {subclass of DS.Model} type
      @param {DS.Snapshot} snapshot
      @return {Promise} promise
    */
    updateRecord: function updateRecord(store, typeClass, snapshot) {
      var data = this.mockJSON(store, typeClass, snapshot);

      this.updateData(typeClass, data);

      return this.simulateRemoteCall(function () {
        return data;
      });
    },

    /**
      @method deleteRecord
      @param {DS.Store} store
      @param {subclass of DS.Model} typeClass
      @param {DS.Snapshot} snapshot
      @return {Promise} promise
    */
    deleteRecord: function deleteRecord(store, typeClass, snapshot) {
      this.deleteLoadedItem(typeClass, snapshot);

      return this.simulateRemoteCall(function () {
        return null;
      });
    },

    /*
      @method deleteLoadedItem
      @private
      @param typeClass
      @param snapshot
    */
    deleteLoadedItem: function deleteLoadedItem(typeClass, snapshot) {
      var existingItem = this.findExistingItem(typeClass, snapshot);

      if (existingItem) {
        var data = this.dataForType(typeClass);
        var index = indexOf(data, existingItem);
        data.splice(index, 1);
        return true;
      }
    },

    /*
      @method findExistingItem
      @private
      @param typeClass
      @param snapshot
    */
    findExistingFixture: function findExistingFixture(typeClass, snapshot) {
      var data = this.dataForType(typeClass);
      var id = snapshot.id;

      return this.findItemById(data, id);
    },

    /*
      @method findFixtureById
      @private
      @param fixtures
      @param id
    */
    findItemById: function findItemById(data, id) {
      return data.find(function (r) {
        return '' + _ember['default'].get(r, 'id') === '' + id;
      });
    },

    /*
      @method simulateRemoteCall
      @private
      @param callback
      @param context
    */
    simulateRemoteCall: function simulateRemoteCall(callback, context) {
      var adapter = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        var value = _ember['default'].copy(callback.call(context), true);
        if (_ember['default'].get(adapter, 'simulateRemoteResponse')) {
          // Schedule with setTimeout
          _ember['default'].run.later(null, resolve, value, _ember['default'].get(adapter, 'latency'));
        } else {
          // Asynchronous, but at the of the runloop with zero latency
          resolve(value);
        }
      }, 'DS: InMemoryAdapter#simulateRemoteCall');
    }
  });
});