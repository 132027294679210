define("ember-pikaday/templates/pikaday-inputless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qx6PioJq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[9,\"class\",\"ember-pikaday-input\"],[9,\"type\",\"hidden\"],[7],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"ember-pikaday-container\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-pikaday/templates/pikaday-inputless.hbs"
    }
  });

  _exports.default = _default;
});