define('ember-rapid-forms/components/em-input', ['exports', 'ember-rapid-forms/templates/components/em-input', 'ember-rapid-forms/mixins/input-component'], function (exports, _emInput, _inputComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputComponent.default, {
    layout: _emInput.default,
    elementClass: null,
    property: null,
    name: null,
    placeholder: null,
    title: null,
    pattern: null,
    autofocus: null,
    readonly: null,
    autoresize: null,
    disabled: null,
    canShowErrors: false,
    maxlength: null,

    hideValidationsOnFormChange: Ember.observer('form', 'form.model', function () {
      this.set('canShowErrors', false);
    }),

    /*
    Observes the helpHasErrors of the help control and modify the 'status' property accordingly.
     */
    focusIn: function focusIn() {
      if (this.get('form.showErrorsOnFocusIn')) {
        return this.set('canShowErrors', true);
      }
    },


    /*
    Listen to the focus out of the form group and display the errors
     */
    focusOut: function focusOut() {
      return this.set('canShowErrors', true);
    },


    /*
    Listen to the keyUp of the form group and display the errors if showOnKeyUp is true.
     */
    keyUp: function keyUp() {
      if (this.get('showOnKeyUp')) {
        return this.set('canShowErrors', true);
      }
    }
  });
});