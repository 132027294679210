define('ember-cli-d3/components/data-visual', ['exports', 'ember', 'ember-cli-d3/system/stage'], function (exports, _ember, _emberCliD3SystemStage) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['data-visual'],

    width: 300,
    height: 150,

    stage: null,

    initializeGraphicsContext: function initializeGraphicsContext() {
      var element = this.element;
      var fragment = document.createDocumentFragment();

      _emberCliD3SystemStage['default'].stages.forEach(function (stage) {
        fragment.appendChild(document.createComment(stage));
      });

      element.insertBefore(fragment, element.firstChild);

      this.set('stage', _emberCliD3SystemStage['default'].create({ element: element }));
      this.measure();
    },

    measure: function measure() {
      this.set('width', this.$().width());
      this.set('height', this.$().height());
    },

    didInsertElement: function didInsertElement() {
      _ember['default'].$(window).on('resize.' + this.get('elementId'), _ember['default'].run.bind(this, this.measure));

      _ember['default'].run.scheduleOnce('render', this, this.initializeGraphicsContext);
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$(window).off('resize.' + this.get('elementId'));
    }

  });
});