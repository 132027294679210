define('ember-rapid-forms/components/control-within-label', ['exports', 'ember-rapid-forms/templates/components/control-within-label'], function (exports, _controlWithinLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _controlWithinLabel.default,
    tagName: ''
  });
});