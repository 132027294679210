define("ember-date-components/components/date-picker", ["exports", "ember-date-components/templates/components/date-picker", "moment"], function (_exports, _datePicker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * A versatile date picker component.
   * This is 100% ember based and uses no other date picker library.
   *
   * @namespace EmberDateComponents
   * @class DatePicker
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _datePicker.default,
    classNames: ['date-picker__wrapper'],
    classNameBindings: ['isOpen:date-picker__wrapper--open'],
    // ATTRIBUTES BEGIN ----------------------------------------

    /**
     * The default value for the date picker.
     * Can be a value or an array.
     * Note that internally, this will always be converted to an array (if for a sinle-date picker field).
     * So it makes no difference if it is val or [val].
     *
     * @attribute value
     * @type {Date}
     * @optional
     * @public
     */
    value: null,

    /**
     * An array of optional dates to disable for this date picker.
     * These dates will not be selectable.
     *
     * @attribute disabledDates
     * @type {Date[]}
     * @optional
     * @public
     */
    disabledDates: null,

    /**
     * An optional minimum date for this date picker.
     * No dates before this date will be selectable.
     *
     * @attribute minDate
     * @type {Date}
     * @optional
     * @public
     */
    minDate: null,

    /**
     * An optional maximum date for this date picker.
     * No dates after this date will be selectable.
     *
     * @attribute masDate
     * @type {Date}
     * @optional
     * @public
     */
    maxDate: null,

    /**
     * If this date picker should select a range instead of a single date.
     * If this is set, the action's parameter will always be an array with two elements, both of which could be null.
     * The dates will always be in order (e.g. earlier date as first element, later date as second element).
     *
     * @attribute range
     * @type {Boolean}
     * @default false
     * @public
     */
    range: false,

    /**
     * The placeholder for the button, if no date is selected.
     *
     * @attribute placeholder
     * @type {String}
     * @default 'Select Date...'
     * @public
     */
    placeholder: 'Select Date...',

    /**
     * Optional classes for the button.
     *
     * @attribute buttonClasses
     * @type {String}
     * @optional
     * @public
     */
    buttonClasses: '',

    /**
     * The date format which should be used for the button.
     * Defaults to localized 'L'.
     *
     * @attribute buttonDateFormat
     * @type {String}
     * @default 'L'
     * @public
     */
    buttonDateFormat: 'L',

    /**
     * If custom options should be displayed.
     * If this is true, the default options for date-pickers/date-range-pickers will be shown.
     * It can also be an array, where the exact options are specified.
     *
     * @attribute options
     * @type {Boolean|Array}
     * @default false
     * @public
     */
    options: false,

    /**
     * If this is true, the date picker is disabled and the selected date cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    /**
     * If set to true, the month picker will not be usable.
     *
     * @attribute disableMonthPicker
     * @type {Boolean}
     * @default false
     * @public
     */
    disableMonthPicker: false,

    /**
     * If set to true, the year picker will not be usable.
     *
     * @attribute disableYearPicker
     * @type {Boolean}
     * @default false
     * @public
     */
    disableYearPicker: false,

    /**
     * The number of years before & after the current year to show in the year picker.
     *
     * @attribute availableYearOffset
     * @type {Number}
     * @default 10
     * @public
     */
    availableYearOffset: 10,

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * @attribute value
     * @type {Boolean}
     * @public
     */
    renderInPlace: false,

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * Available values are right|center|left
     *
     * @attribute value
     * @type {String}
     * @public
     */
    horizontalPosition: 'auto',

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * Available values are above|below
     *
     * @attribute value
     * @type {String}
     * @public
     */
    verticalPosition: 'auto',

    /**
     * The action to call whenever one of the value changes.
     *
     * @event action
     * @param {Date|Date[]} date Either a single date (or null) if `range=false`, or an array with two elements if `range=true`.
     * @public
     */
    action: null,

    /**
     * The action to call whenever the date picker is closed.
     *
     * @event action
     * @param {Date|Date[]} date Either a single date (or null) if `range=false`, or an array with two elements if `range=true`.
     * @public
     */
    closeAction: null,

    /**
     * Whether the calendar displays the week starting on Mondayf or Sunday.
     *
     * @attribute startWeekOnSunday
     * @type {Boolean}
     * @default false
     * @public
     */
    startWeekOnSunday: false,
    // ATTRIBUTES END ----------------------------------------
    // PROPERTIES BEGIN ----------------------------------------

    /**
     * A separator for the date range buttons.
     *
     * @property dateRangeSeparator
     * @type {String}
     * @default ' - '
     * @private
     */
    dateRangeSeparator: ' - ',

    /**
     * The internal dates. No matter if it is a range or a single date selector,
     * the dates will always be saved in this array.
     *
     * @property _dates
     * @type {Date[]}
     * @private
     */
    _dates: null,

    /**
     * The currently visible month.
     * This is set on initialisation. It is either the first selected date (if a value is provided), or today.
     *
     * @property currentMonth
     * @type {Date}
     * @private
     */
    currentMonth: null,

    /**
     * If the current selection is the to-step.
     * This is automatically set internally for a range picker.
     *
     * @property isToStep
     * @type {Boolean}
     * @private
     */
    isToStep: false,

    /**
     * If the date picker is open.
     *
     * @property isOpen
     * @type {Boolean}
     * @private
     */
    isOpen: false,

    /**
     * The text for the button.
     * This will either return the placeholder, or the formatted date.
     *
     * @property buttonText
     * @type {String}
     * @readOnly
     * @private
     */
    buttonText: Ember.computed('range', '_dates.[]', function () {
      var isRange = Ember.get(this, 'range');
      var vals = Ember.get(this, '_dates') || Ember.A([]);
      var dateFormat = Ember.get(this, 'buttonDateFormat');

      var _vals = _slicedToArray(vals, 1),
          dateFrom = _vals[0];

      if (!isRange) {
        if (!dateFrom) {
          return Ember.get(this, 'placeholder');
        }

        return dateFrom.format(dateFormat);
      }

      if (!dateFrom) {
        return Ember.get(this, 'placeholder');
      }

      return dateFrom.format(dateFormat);
    }),

    /**
     * The text for the to-button.
     * This is only used for date range pickers.
     * It will either return the placeholder, or the formatted date.
     *
     * @property buttonToText
     * @type {String}
     * @readOnly
     * @private
     */
    buttonToText: Ember.computed('range', '_dates.[]', function () {
      var vals = Ember.get(this, '_dates') || Ember.A([]);
      var dateFormat = Ember.get(this, 'buttonDateFormat');

      var _vals2 = _slicedToArray(vals, 2),
          dateTo = _vals2[1];

      if (!dateTo) {
        return Ember.get(this, 'placeholder');
      }

      return dateTo.format(dateFormat);
    }),

    /**
     * If the (first) button is currently focused.
     *
     * @property buttonFocused
     * @type {Boolean}
     * @readOnly
     * @private
     */
    buttonFocused: Ember.computed('range', 'isOpen', 'isToStep', function () {
      var isRange = Ember.get(this, 'range');
      var isOpen = Ember.get(this, 'isOpen');
      var isToStep = Ember.get(this, 'isToStep');
      return isRange ? isOpen && !isToStep : isOpen;
    }),

    /**
     * If the to-button is currently focused.
     *
     * @property buttonToFocused
     * @type {Boolean}
     * @readOnly
     * @private
     */
    buttonToFocused: Ember.computed('range', 'isOpen', 'isToStep', function () {
      var isRange = Ember.get(this, 'range');
      var isOpen = Ember.get(this, 'isOpen');
      var isToStep = Ember.get(this, 'isToStep');
      return isRange ? isOpen && isToStep : false;
    }),

    /**
     * An array with all selected dates.
     * This contains only selected dates, no null values! This means it can have zero, one or two values.
     * This is passed to date-picker-month to show the selected dates.
     *
     * @property selectedDates
     * @type {Date[]}
     * @readOnly
     * @private
     */
    selectedDates: Ember.computed('_dates.[]', function () {
      var arr = [];

      var _EmberGet = Ember.get(this, '_dates'),
          _EmberGet2 = _slicedToArray(_EmberGet, 2),
          dateFrom = _EmberGet2[0],
          dateTo = _EmberGet2[1];

      if (dateFrom) {
        arr.push(dateFrom);
      }

      if (dateTo) {
        arr.push(dateTo);
      }

      return Ember.A(arr);
    }),

    /**
     * These are the parsed options.
     * String/default options are converted into actual option objects via _optionsMap.
     *
     * @property _options
     * @type {Object[]}
     * @readOnly
     * @private
     */
    _options: Ember.computed('options.[]', function () {
      var options = Ember.get(this, 'options');
      var isRange = Ember.get(this, 'range');
      var optionsMap = Ember.get(this, '_optionsMap');

      if (!options) {
        return Ember.A();
      } // If options is true, return the default options depending on isRange


      if (Ember.typeOf(options) !== 'array') {
        options = isRange ? Ember.get(this, '_defaultDateRangeOptions') : Ember.get(this, '_defaultDateOptions');
      }

      return options.map(function (option) {
        return Ember.typeOf(option) === 'string' ? optionsMap[option] : option;
      });
    }),

    /**
     * This maps how option names are mapped to actual options.
     * You can overwrite this if you want to have different option shortcuts.
     *
     * @property _optionsMap
     * @type {Object}
     * @private
     */
    _optionsMap: Ember.computed(function () {
      return {
        clear: {
          action: 'clearDate',
          label: 'Clear'
        },
        today: {
          action: 'selectToday',
          label: 'Today'
        },
        last7Days: {
          action: 'selectDateRange',
          label: 'Last 7 days',
          actionValue: [(0, _moment.default)().startOf('day').subtract(6, 'days'), (0, _moment.default)().startOf('day')]
        },
        last30Days: {
          action: 'selectDateRange',
          label: 'Last 30 days',
          actionValue: [(0, _moment.default)().startOf('day').subtract(29, 'days'), (0, _moment.default)().startOf('day')]
        },
        lastYear: {
          action: 'selectDateRange',
          label: 'Last year',
          actionValue: [(0, _moment.default)().startOf('day').subtract(1, 'year').add(1, 'day'), (0, _moment.default)().startOf('day')]
        },
        last3Months: {
          action: 'selectDateRange',
          label: 'Last 3 months',
          actionValue: [(0, _moment.default)().startOf('day').subtract(3, 'months').add(1, 'day'), (0, _moment.default)().startOf('day')]
        },
        last6Months: {
          action: 'selectDateRange',
          label: 'Last 6 months',
          actionValue: [(0, _moment.default)().startOf('day').subtract(6, 'months').add(1, 'day'), (0, _moment.default)().startOf('day')]
        },
        thisWeek: {
          action: 'selectDateRange',
          label: 'This week',
          actionValue: [(0, _moment.default)().startOf('isoweek'), (0, _moment.default)().startOf('day')]
        },
        thisMonth: {
          action: 'selectDateRange',
          label: 'This month',
          actionValue: [(0, _moment.default)().startOf('month'), (0, _moment.default)().startOf('day')]
        }
      };
    }),

    /**
     * The default options for date pickers.
     * You can overwrite this if you want different default options.
     *
     * @property _defaultDateOptions
     * @type {Array}
     * @private
     */
    _defaultDateOptions: Ember.A(['clear', 'today']),

    /**
     * The default options for date range pickers.
     * you can overwrite this if you want different default options.
     *
     * @property _defaultDateRangeOptions
     * @type {Array}
     * @private
     */
    _defaultDateRangeOptions: Ember.A(['clear', 'today', 'last7Days', 'last30Days', 'last3Months']),

    /**
     * The API of ember-basic-dropdown.
     * This is used to manually open/close the dropdown.
     *
     * @property _dropdownApi
     * @type {Object}
     * @private
     */
    _dropdownApi: null,
    // PROPERTIES END ----------------------------------------
    // HOOKS BEGIN ----------------------------------------
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._setupValue();
    },
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, '_dates', []);
    },
    // HOOKS END ----------------------------------------
    // METHODS BEGIN ----------------------------------------

    /**
     * Setup the value.
     * This is called on didReceiveAttrs and transforms the given value into an array which can be used by this component.
     *
     * @method _setupValue
     * @private
     */
    _setupValue: function _setupValue() {
      var val = Ember.get(this, 'value');
      var isRange = Ember.get(this, 'range');

      if (val) {
        if (Ember.typeOf(val) !== 'array') {
          val = Ember.A([val]);
        }
      } else {
        val = Ember.A();
      }

      Ember.set(this, '_dates', val);

      if (val.length > 0) {
        var month = val[0] && _moment.default.isMoment(val[0]) ? val[0].clone().startOf('month') : (0, _moment.default)().startOf('month');
        Ember.set(this, 'currentMonth', month);
      } else {
        var _month = (0, _moment.default)().startOf('month');

        Ember.set(this, 'currentMonth', _month);
      }

      if (val.length === 1 && isRange) {
        Ember.set(this, 'isToStep', true);
        val.pushObject(null);
      } else if (val.length === 0 && isRange) {
        val.pushObjects([null, null]);
      }
    },

    /**
     * Actually send the action.
     *
     * @method _sendAction
     * @private
     */
    _sendAction: function _sendAction() {
      var action = Ember.get(this, 'action');
      var vals = Ember.get(this, '_dates');
      var isRange = Ember.get(this, 'range');

      if (action && !Ember.get(this, 'disabled')) {
        action(isRange ? vals : vals[0] || null);
      }
    },

    /**
     * Open the date picker.
     *
     * @method _open
     * @private
     */
    _open: function _open() {
      var forceOpenDropdown = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      Ember.set(this, 'isOpen', true);

      if (forceOpenDropdown) {
        this._openDropdown();
      } // Move focus to dropdown


      this._focusDatePicker();
    },

    /**
     * Move the focus to the date picker.
     * This is called when `_open` is called, to ensure that the date picker can be used with the keyboard.
     * This will also save the previously focused element, to ensure we can correctly return the focus later.
     *
     * @method _focusDatePicker
     * @private
     */
    _originallyFocusedElement: null,
    _focusDatePicker: function _focusDatePicker() {
      var originallyFocusedElement = document.activeElement;
      Ember.set(this, '_originallyFocusedElement', originallyFocusedElement);

      this._focusButtonInDatePicker();
    },
    _focusButtonInDatePicker: function _focusButtonInDatePicker() {
      var elementId = Ember.get(this, 'elementId');
      Ember.run.next(function () {
        var datePickerDropdown = document.querySelector("[data-date-picker-instance=\"".concat(elementId, "\"]"));
        var selectedButton = datePickerDropdown && datePickerDropdown.querySelector('[data-date-picker-day].date-picker__day--selected');
        var firstButton = datePickerDropdown && datePickerDropdown.querySelector('[data-date-picker-day]');
        var buttonToFocus = selectedButton || firstButton;

        if (buttonToFocus && document.body.contains(buttonToFocus)) {
          buttonToFocus.focus();
        }
      });
    },

    /**
     * Reset the focus to the previously focused element.
     * This is called when the date picker is closed.
     *
     * @method _resetFocus
     * @private
     */
    _resetFocus: function _resetFocus() {
      var originallyFocusedElement = Ember.get(this, '_originallyFocusedElement');
      Ember.set(this, '_originallyFocusedElement', null);

      if (originallyFocusedElement && document.body.contains(originallyFocusedElement)) {
        Ember.run.next(function () {
          return originallyFocusedElement.focus();
        });
      }
    },

    /**
     * Close the date picker.
     *
     * @method _close
     * @private
     */
    _close: function _close() {
      var sendAction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var forceCloseDropdown = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      Ember.set(this, 'isOpen', false);
      Ember.set(this, 'isToStep', false);

      if (sendAction) {
        Ember.run.once(this, this._sendCloseAction);
      }

      if (forceCloseDropdown) {
        this._closeDropdown();

        this._resetFocus();
      }
    },
    _sendCloseAction: function _sendCloseAction() {
      var action = Ember.get(this, 'closeAction');
      var vals = Ember.get(this, '_dates');
      var isRange = Ember.get(this, 'range');

      if (action) {
        action(isRange ? vals : vals[0] || null);
      }
    },
    _closeDropdown: function _closeDropdown() {
      var dropdownApi = Ember.get(this, '_dropdownApi');

      if (dropdownApi) {
        dropdownApi.actions.close();
      }
    },
    _openDropdown: function _openDropdown() {
      var dropdownApi = Ember.get(this, '_dropdownApi');

      if (dropdownApi) {
        dropdownApi.actions.open();
      }
    },

    /**
     * Set a single date value.
     * It will also close the date picker.
     *
     * @method _setSingleDate
     * @param {Date[]} date The selected date in an array
     * @returns {Date[]}
     * @private
     */
    _setSingleDate: function _setSingleDate(date) {
      var vals = Ember.A([date]);
      Ember.set(this, '_dates', vals);

      this._close();

      return vals;
    },

    /**
     * Set the from date to the selected date.
     * It might also switch the to-date with the from-date if the to-date is before the from-date.
     *
     * @method _setFromDate
     * @param date
     * @private
     */
    _setFromDate: function _setFromDate(date) {
      var dates = Ember.get(this, '_dates');

      var _dates = _slicedToArray(dates, 2),
          dateTo = _dates[1];

      var vals;

      if (dateTo && date.valueOf() > dateTo.valueOf()) {
        vals = Ember.A([date, null]);
      } else {
        vals = Ember.A([date, dateTo || null]);
      }

      Ember.set(this, '_dates', vals);
    },

    /**
     * Set the to date to the selected date.
     * It might also switch the to-date with the from-date if the to-date is before the from-date.
     *
     * @method _setToDate
     * @param date
     * @private
     */
    _setToDate: function _setToDate(date) {
      var dates = Ember.get(this, '_dates');

      var _dates2 = _slicedToArray(dates, 1),
          dateFrom = _dates2[0];

      var vals;

      if (date) {
        date = date.endOf('day');
      }

      if (date && dateFrom && date.valueOf() < dateFrom.valueOf()) {
        vals = Ember.A([date, dateFrom]);
      } else {
        vals = Ember.A([dateFrom, date]);
      }

      Ember.set(this, '_dates', vals);
    },

    /**
     * Set date range values.
     * It will also close the date picker if it is the to-date.
     *
     * @method _setDateRange
     * @param {Date[]} date The selected dates in an array
     * @returns {Date[]}
     * @private
     */
    _setDateRange: function _setDateRange(date) {
      var isToStep = Ember.get(this, 'isToStep');

      if (!isToStep) {
        this._setFromDate(date);

        this._moveToToStep();
      } else {
        this._setToDate(date);

        this._close();
      }
    },

    /**
     * Move to the from step.
     * This will set the current month to the month of the from-step (if a from-date is set)
     *
     * @method _moveToFromStep
     * @private
     */
    _moveToFromStep: function _moveToFromStep() {
      var _ref = Ember.get(this, '_dates') || Ember.A(),
          _ref2 = _slicedToArray(_ref, 1),
          month = _ref2[0];

      if (month) {
        Ember.set(this, 'currentMonth', month.clone().startOf('month'));
      }

      Ember.set(this, 'isToStep', false);

      this._openDropdown();

      this._focusButtonInDatePicker();
    },

    /**
     * Move to the to step.
     * This will set the current month to the month of the to-step (if a to-date is set)
     *
     * @method _moveToToStep
     * @private
     */
    _moveToToStep: function _moveToToStep() {
      var _ref3 = Ember.get(this, '_dates') || Ember.A(),
          _ref4 = _slicedToArray(_ref3, 2),
          month = _ref4[1];

      if (month) {
        Ember.set(this, 'currentMonth', month.clone().startOf('month'));
      }

      Ember.set(this, 'isToStep', true);

      this._openDropdown();

      this._focusButtonInDatePicker();
    },

    /**
     * Move to the from date and open the date picker.
     *
     * @method _openFromDate
     * @private
     */
    _openFromDate: function _openFromDate() {
      this._moveToFromStep();

      this._open();
    },

    /**
     * Move to the to step and open the date picker.
     *
     * @method _openToDate
     * @private
     */
    _openToDate: function _openToDate() {
      this._moveToToStep();

      this._open();
    },
    // METHODS END ----------------------------------------
    // ACTIONS BEGIN ----------------------------------------
    actions: {
      clearDate: function clearDate() {
        Ember.set(this, '_dates', Ember.A());
        Ember.set(this, 'isToStep', false);

        this._sendAction();

        this._close();
      },
      selectToday: function selectToday() {
        var today = (0, _moment.default)().startOf('day');

        if (Ember.get(this, 'range')) {
          Ember.set(this, '_dates', Ember.A([today, today]));
        } else {
          Ember.set(this, '_dates', Ember.A([today]));
        }

        this._sendAction();

        this._close();
      },
      toggleOpen: function toggleOpen() {
        var isOpen = Ember.get(this, 'isOpen');
        var isRange = Ember.get(this, 'range');
        var isToStep = Ember.get(this, 'isToStep');

        if (!isRange) {
          if (isOpen) {
            this._close();
          } else {
            this._openFromDate();
          }

          return;
        }

        if (isOpen) {
          // If it is a range picker, either close it or switch to isToStep=false
          if (isToStep) {
            this._moveToFromStep();
          } else {
            this._close();
          }
        } else {
          this._openFromDate();
        }
      },
      toggleOpenTo: function toggleOpenTo() {
        var isOpen = Ember.get(this, 'isOpen');
        var isToStep = Ember.get(this, 'isToStep');

        if (isOpen) {
          if (!isToStep) {
            this._moveToToStep();
          } else {
            this._close();
          }
        } else {
          this._openToDate();
        }
      },
      gotoMonth: function gotoMonth(month) {
        Ember.set(this, 'currentMonth', month);
      },
      selectDate: function selectDate(date) {
        var isRange = Ember.get(this, 'range');

        if (!isRange) {
          this._setSingleDate(date);
        } else {
          this._setDateRange(date);
        }

        this._sendAction();
      },
      selectDateRange: function selectDateRange(dates) {
        Ember.set(this, '_dates', Ember.A(dates));

        this._sendAction();

        this._close();
      },
      onDropdownClosed: function onDropdownClosed() {
        this._close(true, false);
      },
      onDropdownOpened: function onDropdownOpened(dropdownApi) {
        Ember.set(this, '_dropdownApi', dropdownApi);
      }
    } // ACTIONS END ----------------------------------------

  });

  _exports.default = _default;
});