define("ember-rapid-forms/templates/components/em-form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9869gyol",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"wrapperClass\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[18,\"wrapperClass\"],null],[7],[0,\"\\n\"],[4,\"form-group\",null,[[\"yieldInLabel\",\"labelInControl\",\"formComponent\",\"form\"],[[20,[\"yieldInLabel\"]],[20,[\"labelInControl\"]],[19,0,[]],[20,[\"form\"]]]],{\"statements\":[[0,\"            \"],[11,1,[[19,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"form-group\",null,[[\"yieldInLabel\",\"labelInControl\",\"formComponent\",\"form\"],[[20,[\"yieldInLabel\"]],[20,[\"labelInControl\"]],[19,0,[]],[20,[\"form\"]]]],{\"statements\":[[0,\"        \"],[11,1,[[19,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/em-form-group.hbs"
    }
  });

  _exports.default = _default;
});