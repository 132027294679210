define('ember-cli-d3/mixins/d3-support', ['exports', 'ember', 'ember-getowner-polyfill', 'd3', 'ember-cli-d3/utils/version'], function (exports, _ember, _emberGetownerPolyfill, _d3, _emberCliD3UtilsVersion) {

  var GraphicSupport = _ember['default'].Mixin.create({
    concatenatedProperties: ['requiredProperties'],
    requiredProperties: ['select'],

    tagName: '',

    select: null,
    model: null,

    call: function call() {},

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var selection = this.get('select');

      if (selection && !this.isDestroying && this.get('requiredProperties').map(function (prop) {
        return Boolean(!!_this.get(prop));
      }).reduce(function (prev, cur) {
        return prev && cur;
      }, true)) {
        _ember['default'].run.scheduleOnce('afterRender', selection, selection.call, this);
      }
    }
  });

  // if a base tag is present
  if (!_d3['default'].select('head base').empty()) {
    GraphicSupport.reopen({
      didTransition: function didTransition() {
        _ember['default'].run.scheduleOnce('render', this, this.didReceiveAttrs);
      },

      didInsertElement: function didInsertElement() {
        var router = (0, _emberGetownerPolyfill['default'])(this).lookup('router:main');

        this._super.apply(this, arguments);

        router.on('didTransition', this, this.didTransition);
      },

      willDestroyElement: function willDestroyElement() {
        var router = (0, _emberGetownerPolyfill['default'])(this).lookup('router:main');

        this._super.apply(this, arguments);

        router.off('didTransition', this, this.didTransition);
      }
    });
  }

  if (!_emberCliD3UtilsVersion.hasGlimmer) {
    GraphicSupport.reopen({
      init: function init() {
        var _this2 = this;

        this._super.apply(this, arguments);

        Object.keys(this).forEach(function (key) {
          var index = key.indexOf('Binding');

          if (key[0] !== '_' && index > 0) {
            _this2.addObserver(key.substring(0, index), _this2, function () {
              _ember['default'].run.scheduleOnce('render', _this2, _this2.didReceiveAttrs);
            });
          }
        });
      },

      didInsertElement: function didInsertElement() {
        this._super.apply(this, arguments);

        _ember['default'].run.scheduleOnce('render', this, this.didReceiveAttrs);
      }

    });
  } else {
    GraphicSupport.reopen({
      didInsertElement: function didInsertElement() {
        var index = (0, _emberGetownerPolyfill['default'])(this).lookup("-view-registry:main");

        this._super.apply(this, arguments);

        index[this.elementId] = this;
      },

      willDestroyElement: function willDestroyElement() {
        var index = (0, _emberGetownerPolyfill['default'])(this).lookup("-view-registry:main");

        this._super.apply(this, arguments);

        delete index[this.elementId];
      }

    });
  }

  exports['default'] = GraphicSupport;
});