define("ember-rapid-forms/templates/components/control-within-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZafTcahM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"em-form-label\",null,[[\"text\",\"horiClass\",\"inlineClass\",\"form\",\"for\",\"extraClass\"],[[20,[\"label\"]],\"\",\"\",[20,[\"form\"]],[20,[\"formComponent\",\"inputId\"]],[20,[\"extraClass\"]]]],{\"statements\":[[4,\"form-group-control\",null,[[\"controlWrapper\",\"formComponent\",\"form\"],[[20,[\"controlWrapper\"]],[20,[\"formComponent\"]],[20,[\"form\"]]]],{\"statements\":[[0,\"        \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/control-within-label.hbs"
    }
  });

  _exports.default = _default;
});