define("ember-date-components/components/time-picker-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is an extended {{input}} to send appropriate events for arrowUp/arrowDown.
   * It is also slightly changed to send an input-changed event when a key that is neither arrowUp/arrowDown, enter or escape
   * is pressed.
   *
   * @namespace EmberDateComponents
   * @class TimePickerInput
   * @extends Ember.TextField
   * @public
   */
  var _default = Ember.TextField.extend({
    classNames: [],
    attributeBindings: ['disabled', 'data-time-picker-input-instance', 'tabindex'],
    type: 'text',
    tabindex: -1,

    /**
     * If this is true, the time picker is disabled and the selected time cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,
    keyUp: function keyUp() {// overwrite default implementation
    },
    keyDown: function keyDown(event) {
      // Tab doesn't trigger keyUp, so we need to capture it in keyDown
      switch (event.key) {
        case 'Enter':
          return this._enter(event);

        case 'Escape':
          return this._escape(event);

        case 'ArrowUp':
          return this._arrowUp(event);

        case 'ArrowDown':
          return this._arrowDown(event);

        case 'Tab':
          return this._tab(event);
      }
    },
    input: function input() {
      Ember.run.once(this, this.inputChange);
    },
    inputChange: function inputChange() {
      this._elementValueDidChange();

      var value = Ember.get(this, 'value');
      var action = Ember.get(this, 'input-change');
      return action(value, this);
    },
    _tab: function _tab(event) {
      var action = Ember.get(this, 'tab');
      return action(this, event);
    },
    _arrowUp: function _arrowUp(event) {
      var action = Ember.get(this, 'arrow-up');
      return action(this, event);
    },
    _arrowDown: function _arrowDown(event) {
      var action = Ember.get(this, 'arrow-down');
      return action(this, event);
    },
    _escape: function _escape(event) {
      var action = Ember.get(this, 'escape');
      return action(this, event);
    },
    _enter: function _enter(event) {
      var action = Ember.get(this, 'enter');
      return action(this, event);
    }
  });

  _exports.default = _default;
});