define('ember-cli-jstree/components/ember-jstree', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'ember-cli-jstree/mixins/ember-jstree-actions'], function (exports, _ember, _emberComponentInboundActionsInboundActions, _emberCliJstreeMixinsEmberJstreeActions) {
    exports['default'] = _ember['default'].Component.extend(_emberComponentInboundActionsInboundActions['default'], _emberCliJstreeMixinsEmberJstreeActions['default'], {

        // Properties for Ember communication
        actionReceiver: null,
        currentNode: null,
        selectedNodes: null,

        // Basic configuration objects
        data: null,
        plugins: null,
        themes: null,
        checkCallback: true,
        multiple: true,

        // Refresh configuration variables
        skipLoading: false,
        forgetState: false,

        // Plugin option objects
        checkboxOptions: null,
        contextmenuOptions: null,
        typesOptions: null,
        searchOptions: null,

        selectionDidChange: null,
        treeObject: null,

        // Internals
        _isDestroying: false,

        isReady: false,
        _searchTerm: null,

        _isReadyTestWaiter: function _isReadyTestWaiter() {
            return this.get('isReady') === true;
        },

        didInsertElement: function didInsertElement() {
            _ember['default'].run.schedule('afterRender', this, this.createTree);
        },

        createTree: function createTree() {
            if (_ember['default'].testing) {
                // Add test waiter.
                _ember['default'].Test.registerWaiter(this, this._isReadyTestWaiter);
            }

            var treeObject = this._setupJsTree();

            this._setupEventHandlers(treeObject);

            this.set('treeObject', treeObject);
        },

        willDestroyElement: function willDestroyElement() {
            if (_ember['default'].testing) {
                _ember['default'].Test.unregisterWaiter(this, this._isReadyTestWaiter);
            }

            this.set('isReady', false);
            this.set('_isDestroying', true);
            this.send('destroy');
        },

        didUpdateAttrs: function didUpdateAttrs() {
            this._super.apply(this, arguments);

            var pluginsArray = this.get('plugins');
            if (_ember['default'].isPresent(pluginsArray)) {
                var searchOptions = this.get('searchOptions');
                if (_ember['default'].isPresent(searchOptions) && pluginsArray.includes('search')) {
                    var searchTerm = this.get('searchTerm');
                    if (this.get('_searchTerm') !== searchTerm) {
                        this.set('_searchTerm', searchTerm);
                        this.getTree().search(searchTerm);
                    }
                }
            }
        },

        /**
         * Main setup function that registers all the plugins and sets up the core
         * configuration object for jsTree
         *
         * @method _setupJsTree
         */
        _setupJsTree: function _setupJsTree() {
            return this.$().jstree(this._buildConfig());
        },

        /**
         * Builds config object for jsTree. Could be used to override config in descendant classes.
         *
         * @method _buildConfig
         */
        _buildConfig: function _buildConfig() {
            var configObject = {};

            configObject['core'] = {
                'data': this.get('data'),
                'check_callback': this.get('checkCallback'),
                'multiple': this.get('multiple')
            };

            var themes = this.get('themes');
            if (_ember['default'].isPresent(themes) && _ember['default'].typeOf(themes) === 'object') {
                configObject['core']['themes'] = themes;
            }

            var pluginsArray = this.get('plugins');
            if (_ember['default'].isPresent(pluginsArray)) {
                pluginsArray = pluginsArray.replace(/ /g, '').split(',');
                configObject['plugins'] = pluginsArray;

                if (pluginsArray.includes('contextmenu') || pluginsArray.includes('dnd') || pluginsArray.includes('unique')) {
                    // These plugins need core.check_callback
                    configObject['core']['check_callback'] = configObject['core']['check_callback'] || true;
                }

                var checkboxOptions = this.get('checkboxOptions');
                if (_ember['default'].isPresent(checkboxOptions) && pluginsArray.includes('checkbox')) {
                    configObject['checkbox'] = checkboxOptions;
                }

                var searchOptions = this.get('searchOptions');
                if (_ember['default'].isPresent(searchOptions) && pluginsArray.includes('search')) {
                    configObject['search'] = searchOptions;
                }

                var stateOptions = this.get('stateOptions');
                if (_ember['default'].isPresent(stateOptions) && pluginsArray.includes('state')) {
                    configObject['state'] = stateOptions;
                }

                var typesOptions = this.get('typesOptions');
                if (_ember['default'].isPresent(typesOptions) && pluginsArray.includes('types')) {
                    configObject['types'] = typesOptions;
                }

                var contextmenuOptions = this.get('contextmenuOptions');
                if (_ember['default'].isPresent(contextmenuOptions) && pluginsArray.includes('contextmenu')) {
                    configObject['contextmenu'] = this._setupContextMenus(contextmenuOptions);
                }
            }

            return configObject;
        },

        /**
         * Setup context menu action handlers to handle ember actions
         *
         * @method _setupContextMenus
         * @param  {Array} contextmenuOptions Context menu configuration options
         * @return {Array} An Array of Ember-friendly options to pass back into the config object
         */
        _setupContextMenus: function _setupContextMenus(contextmenuOptions) {
            var _this = this;

            if (_ember['default'].typeOf(contextmenuOptions['items']) === 'object') {
                var newMenuItems = {};
                var menuItems = Object.keys(contextmenuOptions['items']);
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = menuItems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var menuItem = _step.value;

                        var itemData = contextmenuOptions['items'][menuItem];
                        newMenuItems[menuItem] = itemData;

                        // Only change if not a function
                        // This needs to be done to handle Ember actions
                        if (_ember['default'].typeOf(itemData['action']) !== 'function') {
                            (function () {
                                var emberAction = itemData['action'];

                                newMenuItems[menuItem]['action'] = function (data) {
                                    _this.send("contextmenuItemDidClick", emberAction, data);
                                };
                            })();
                        }
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator['return']) {
                            _iterator['return']();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                contextmenuOptions['items'] = newMenuItems;
            }

            return contextmenuOptions;
        },

        /**
         * Register all sorts of events
         * TODO: This should eventually encompass all of the jsTree events declared in their API.
         *
         * @method _setupEventHandlers
         * @param  {Object}
         * @return
         */
        _setupEventHandlers: function _setupEventHandlers(treeObject) {
            var _this2 = this;

            if (typeof treeObject !== 'object') {
                throw new Error('You must pass a valid jsTree object to set up its event handlers');
            }

            /*
              Event: init.jstree
              Action: eventDidInit
              triggered after all events are bound
            */
            treeObject.on('init.jstree', function () {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidInit');
                });
            });

            /*
              Event: ready.jstree
              Action: eventDidBecomeReady
              triggered after all nodes are finished loading
            */
            treeObject.on('ready.jstree', function () {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.set('isReady', true);
                    this.sendAction('eventDidBecomeReady');
                });
            });

            /*
              Event: redraw.jstree
              Action: eventDidRedraw
              triggered after nodes are redrawn
            */
            treeObject.on('redraw.jstree', function () {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidRedraw');
                });
            });

            /*
              Event: after_open.jstree
              Action: eventDidOpen
              triggered when a node is opened and the animation is complete
            */
            treeObject.on('after_open.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidOpen', data.node);
                });
            });

            /*
              Event: after_close.jstree
              Action: eventDidClose
              triggered when a node is closed and the animation is complete
            */
            treeObject.on('after_close.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidClose', data.node);
                });
            });

            /*
              Event: select_node.jstree
              Action: eventDidSelectNode
              triggered when an node is selected
            */
            treeObject.on('select_node.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidSelectNode', data.node, data.selected, data.event);
                });
            });

            /*
              Event: deselect_node.jstree
              Action: eventDidDeelectNode
              triggered when an node is deselected
            */
            treeObject.on('deselect_node.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidDeselectNode', data.node, data.selected, data.event);
                });
            });

            /*
              Event: changed.jstree
              Action: jstreeDidChange
              triggered when selection changes
            */
            treeObject.on('changed.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }

                    // Check if selection changed
                    if (_ember['default'].isPresent(this.get('treeObject'))) {
                        var selectionChangedEventNames = ['model', 'select_node', 'deselect_node', 'select_all', 'deselect_all'];
                        if (_ember['default'].isPresent(data.action) && selectionChangedEventNames.includes(data.action)) {
                            var selNodes = _ember['default'].A(this.get('treeObject').jstree(true).get_selected(true));
                            this.set('selectedNodes', selNodes);
                        }
                    }

                    this.sendAction('eventDidChange', data);
                });
            });

            /*
              Event: hover_node.jstree
              Action: eventDidHoverNode
              triggered when a node is hovered
            */
            treeObject.on('hover_node.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidHoverNode', data.node);
                });
            });

            /*
              Event: dehover_node.jstree
              Action: eventDidDehoverNode
              triggered when a node is no longer hovered
            */
            treeObject.on('dehover_node.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidDehoverNode', data.node);
                });
            });

            /*
              Event: show_node.jstree
              Action: eventDidShowNode
              triggered when a node is no longer hovered
            */
            treeObject.on('show_node.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidShowNode', data.node);
                });
            });

            /*
              Event: move_node.jstree
              Action: eventDidMoveNode
              triggered when a node is moved
            */
            treeObject.on('move_node.jstree', function (event, data) {
                _ember['default'].run(_this2, function () {
                    if (this.get('isDestroyed') || this.get('isDestroying')) {
                        return;
                    }
                    this.sendAction('eventDidMoveNode', data);
                });
            });
        },

        /**
         * Refreshes the data in the tree
         * TODO: Investigate why redraw(true) doesn't work...
         *
         * @method _redrawTree
         */
        _refreshTree: _ember['default'].observer('data', function () {
            var tree = this.getTree();
            if (null !== tree && false !== tree) {
                tree.settings.core['data'] = this.get('data');
                tree.refresh(this.get('skipLoading'), this.get('forgetState'));
            } else {
                // setup again if destroyed
                var treeObject = this._setupJsTree();
                this._setupEventHandlers(treeObject);
                this.set('treeObject', treeObject);
            }
        }),

        getTree: function getTree() {
            var tree = this.get('treeObject');
            return tree.jstree(true);
        },

        actions: {

            contextmenuItemDidClick: function contextmenuItemDidClick(actionName, data) {
                var emberTreeObj = this.get('getTree');

                var instance = _ember['default'].$.jstree.reference(data.reference);
                var node = instance.get_node(data.reference);

                if (_ember['default'].isPresent(actionName)) {
                    this.sendAction(actionName, node, emberTreeObj);
                }
            }
        }

    });
});
/*jshint loopfunc: true */