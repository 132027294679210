define("ember-date-components/templates/components/date-picker-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zv+Keu3u",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"date-picker date-picker--inline \",[25,\"if\",[[20,[\"options\"]],\" date-picker--options\"],null]]]],[10,\"style\",[18,\"translateX\"],null],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"date-picker__calendar\"],[7],[0,\"\\n\\n    \"],[1,[25,\"date-picker-navigation\",null,[[\"currentMonth\",\"minDate\",\"maxDate\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"class\",\"updateMonth\"],[[20,[\"currentMonth\"]],[20,[\"minDate\"]],[20,[\"maxDate\"]],[20,[\"disableMonthPicker\"]],[20,[\"disableYearPicker\"]],[20,[\"availableYearOffset\"]],\"date-picker__header\",[25,\"action\",[[19,0,[]],\"gotoMonth\"],null]]]],false],[0,\"\\n\\n    \"],[1,[25,\"date-picker-month\",null,[[\"month\",\"selectedDates\",\"selectDate\",\"minDate\",\"maxDate\",\"startWeekOnSunday\"],[[20,[\"currentMonth\"]],[20,[\"selectedDates\"]],[25,\"action\",[[19,0,[]],\"selectDate\"],null],[20,[\"minDate\"]],[20,[\"maxDate\"]],[20,[\"startWeekOnSunday\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"date-picker__options\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"_options\"]]],null,{\"statements\":[[0,\"        \"],[6,\"button\"],[9,\"type\",\"button\"],[10,\"class\",[26,[\"date-picker__options__button \",[19,1,[\"classes\"]]]]],[3,\"action\",[[19,0,[]],[19,1,[\"action\"]],[19,1,[\"actionValue\"]]]],[7],[0,\"\\n          \"],[1,[19,1,[\"label\"]],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-picker-inline.hbs"
    }
  });

  _exports.default = _default;
});