define("ember-rapid-forms/templates/components/em-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1yEGkupP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1,[[25,\"hash\",null,[[\"input\",\"checkbox\",\"select\",\"text\",\"custom-input\",\"submit\",\"group-control\",\"group\"],[[25,\"component\",[\"em-input\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]],[25,\"component\",[\"em-checkbox\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]],[25,\"component\",[\"em-select\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]],[25,\"component\",[\"em-text\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]],[25,\"component\",[\"em-custom-input\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]],[25,\"component\",[\"em-form-submit\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]],[25,\"component\",[\"form-group-control\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]],[25,\"component\",[\"em-form-group\"],[[\"form\",\"model\"],[[19,0,[]],[20,[\"model\"]]]]]]]]]],[0,\"\\n\"],[4,\"if\",[[20,[\"submitButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"em-form-submit\",null,[[\"form\",\"submit\"],[[19,0,[]],\"submit\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/em-form.hbs"
    }
  });

  _exports.default = _default;
});