define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xCM911PY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"searchEnabled\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"ember-power-select-search\"],[7],[0,\"\\n    \"],[6,\"input\"],[9,\"type\",\"search\"],[9,\"autocomplete\",\"off\"],[9,\"autocorrect\",\"off\"],[9,\"autocapitalize\",\"off\"],[9,\"spellcheck\",\"false\"],[9,\"role\",\"combobox\"],[9,\"class\",\"ember-power-select-search-input\"],[10,\"value\",[20,[\"select\",\"searchText\"]],null],[10,\"aria-controls\",[18,\"listboxId\"],null],[10,\"placeholder\",[18,\"searchPlaceholder\"],null],[10,\"oninput\",[18,\"onInput\"],null],[10,\"onfocus\",[18,\"onFocus\"],null],[10,\"onblur\",[18,\"onBlur\"],null],[10,\"onkeydown\",[25,\"action\",[[19,0,[]],\"onKeydown\"],null],null],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
    }
  });

  _exports.default = _default;
});