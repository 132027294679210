define("ember-data-has-many-query/belongs-to-sticky", ["exports", "ember-data", "ember-data-has-many-query/property-names"], function (_exports, _emberData, _propertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.recordHasId = void 0;

  var recordHasId = function recordHasId(record) {
    return record && record.get('id');
  };
  /**
   * Create an extension to the `DS.belongsTo` computed property that returns a cached
   * record if the current associated belongsTo record doesn't have an id.
   *
   * This may be useful if querying a hasMany relationship multiple times and storing
   * the results, as each query will reset the ManyArray and therefore remove the inverse
   * belongsTo association. Defining a relationship as `belongsToSticky` will keep the
   * associated record even if it is removed from the ManyArray.
   *
   * @returns {Ember.computed} relationship
   */


  _exports.recordHasId = recordHasId;

  var belongsToSticky = function belongsToSticky() {
    var _computed = _emberData.default.belongsTo.apply(_emberData.default, arguments);

    var meta = _computed.meta();

    meta.sticky = true;
    return Ember.computed({
      get: function get(key) {
        var _computed$_getter;

        var value = (_computed$_getter = _computed._getter).call.apply(_computed$_getter, [this].concat(Array.prototype.slice.call(arguments)));

        if (recordHasId(value)) {
          return value;
        }

        return this.get((0, _propertyNames.stickyPropertyName)(key)) || value;
      },
      set: function set(key) {
        var _computed$_setter;

        this.set((0, _propertyNames.stickyPropertyName)(key), undefined);
        return (_computed$_setter = _computed._setter).call.apply(_computed$_setter, [this].concat(Array.prototype.slice.call(arguments)));
      }
    }).meta(meta);
  };

  var _default = belongsToSticky;
  _exports.default = _default;
});