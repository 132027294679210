define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TuJYX+nv",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[9,\"class\",\"x-toggle\"],[9,\"type\",\"checkbox\"],[10,\"checked\",[19,0,[\"toggled\"]],null],[10,\"disabled\",[19,0,[\"disabled\"]],null],[10,\"id\",[19,0,[\"forId\"]],null],[10,\"name\",[19,0,[\"name\"]],null],[10,\"onchange\",[25,\"action\",[[19,0,[]],[19,0,[\"sendToggle\"]]],[[\"value\"],[\"target.checked\"]]],null],[7],[8],[0,\"\\n\\n\"],[6,\"label\"],[10,\"for\",[19,0,[\"effectiveForId\"]],null],[7],[0,\"\\n  \"],[6,\"div\"],[10,\"id\",[26,[\"x-toggle-visual-\",[19,0,[\"forId\"]]]]],[9,\"role\",\"checkbox\"],[10,\"class\",[26,[\"x-toggle-btn \",[19,0,[\"themeClass\"]],\" \",[19,0,[\"size\"]],[25,\"if\",[[19,0,[\"disabled\"]],\" x-toggle-disabled\"],null]]]],[10,\"aria-owns\",[19,0,[\"forId\"]],null],[10,\"aria-checked\",[19,0,[\"toggled\"]],null],[10,\"data-tg-on\",[19,0,[\"onLabel\"]],null],[10,\"data-tg-off\",[19,0,[\"offLabel\"]],null],[7],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});