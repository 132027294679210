define("ember-modal-dialog/templates/components/liquid-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E+4qDz1b",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[20,[\"hasOverlay\"]]],null,{\"statements\":[[4,\"liquid-wormhole\",null,[[\"stack\",\"class\"],[\"modal-overlay\",\"liquid-dialog-container\"]],{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[18,\"overlayClassNamesString\"],null],[10,\"onclick\",[25,\"action\",[[19,0,[]],[20,[\"onClickOverlay\"]]],null],null],[9,\"tabindex\",\"-1\"],[9,\"data-emd-overlay\",\"\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"liquid-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\",\"stack\",\"value\"],[[20,[\"containerClassNamesString\"]],[20,[\"tetherTarget\"]],[20,[\"attachment\"]],[20,[\"targetAttachment\"]],[20,[\"targetModifier\"]],[20,[\"tetherClassPrefix\"]],[20,[\"offset\"]],[20,[\"targetOffset\"]],[20,[\"constraints\"]],[20,[\"stack\"]],[20,[\"value\"]]]],{\"statements\":[[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/liquid-tether-dialog.hbs"
    }
  });

  _exports.default = _default;
});