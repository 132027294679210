define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QdtmUEOJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[20,[\"modalDialogComponentName\"]]],[[\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"legacyTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"stack\",\"value\",\"onClickOverlay\",\"onClose\"],[[20,[\"wrapperClass\"]],[20,[\"wrapperClassNames\"]],[20,[\"overlayClass\"]],[20,[\"overlayClassNames\"]],[20,[\"containerClass\"]],[20,[\"containerClassNames\"]],[20,[\"hasOverlay\"]],[20,[\"translucentOverlay\"]],[20,[\"clickOutsideToClose\"]],[20,[\"destinationElementId\"]],[20,[\"overlayPosition\"]],[20,[\"tetherTarget\"]],[20,[\"target\"]],[20,[\"attachment\"]],[20,[\"targetAttachment\"]],[20,[\"targetModifier\"]],[20,[\"targetOffset\"]],[20,[\"offset\"]],[20,[\"tetherClassPrefix\"]],[20,[\"constraints\"]],[20,[\"attachmentClass\"]],[20,[\"targetAttachmentClass\"]],[20,[\"stack\"]],[20,[\"value\"]],[25,\"action\",[[19,0,[]],\"onClickOverlay\"],null],[25,\"action\",[[19,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs"
    }
  });

  _exports.default = _default;
});