define("ember-rapid-forms/templates/components/em-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6m4Zedjf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"em-form-group\",null,[[\"model\",\"property\",\"inputComponent\"],[[20,[\"model\"]],[20,[\"property\"]],[19,0,[]]]],{\"statements\":[[0,\"  \"],[1,[25,\"textarea\",null,[[\"type\",\"rows\",\"cols\",\"placeholder\",\"value\",\"name\",\"disabled\",\"class\",\"id\",\"required\",\"title\",\"pattern\",\"autofocus\",\"readonly\",\"autoresize\"],[[20,[\"type\"]],[20,[\"rows\"]],[20,[\"cols\"]],[20,[\"placeholder\"]],[25,\"mut\",[[25,\"get\",[[20,[\"model\"]],[20,[\"property\"]]],null]],null],[20,[\"name\"]],[20,[\"disabled\"]],[25,\"concat\",[\"form-control \",[20,[\"elementClass\"]]],null],[20,[\"inputId\"]],[20,[\"required\"]],[20,[\"title\"]],[20,[\"pattern\"]],[20,[\"autofocus\"]],[20,[\"readonly\"]],[20,[\"autoresize\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/em-text.hbs"
    }
  });

  _exports.default = _default;
});