define('ember-rapid-forms/mixins/input-errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super();
      this.set('visibleErrors', {});
    },
    showErrors: function showErrors() {
      this.changeErrorsVisibility(true);
    },
    hideErrors: function hideErrors() {
      this.changeErrorsVisibility(false);
    },
    changeErrorsVisibility: function changeErrorsVisibility(visible) {
      var _this = this;

      var validatableAttributes = this.get('validations.validatableAttributes');
      var dependentValidationKeys = this.get('dependentValidationKeys');

      this.eachAttribute(function (key) {
        _this.set('visibleErrors.' + key, visible);
      });

      this.eachRelationship(function (key) {
        _this.set('visibleErrors.' + key, visible);
      });

      if (validatableAttributes) {
        validatableAttributes.forEach(function (key) {
          _this.set('visibleErrors.' + key, visible);
        });
      }

      if (dependentValidationKeys) {
        Object.keys(dependentValidationKeys).forEach(function (key) {
          if (key.indexOf('.') === -1) {
            _this.set('visibleErrors.' + key, visible);
          }
        });
      }
    }
  });
});