define("ui-slider/templates/components/ui-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tqWEr8mR",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[9,\"type\",\"text\"],[10,\"id\",[26,[\"slider-value-\",[18,\"elementId\"]]]],[9,\"class\",\"slider-value\"],[7],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-slider/templates/components/ui-slider.hbs"
    }
  });

  _exports.default = _default;
});