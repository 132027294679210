define("ember-rapid-forms/templates/components/em-checkbox-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bpiaafME",
    "block": "{\"symbols\":[],\"statements\":[[4,\"em-form-label\",null,[[\"text\",\"horiClass\",\"inlineClass\",\"form\",\"for\",\"extraClass\"],[[20,[\"label\"]],\"\",\"\",[20,[\"form\"]],[20,[\"inputId\"]],[20,[\"labelClass\"]]]],{\"statements\":[[0,\"    \"],[1,[25,\"input\",null,[[\"id\",\"type\",\"checked\",\"disabled\",\"required\",\"autofocus\",\"readonly\",\"title\",\"class\",\"name\"],[[20,[\"inputId\"]],\"checkbox\",[25,\"mut\",[[25,\"get\",[[20,[\"model\"]],[20,[\"property\"]]],null]],null],[20,[\"disabled\"]],[20,[\"required\"]],[20,[\"autofocus\"]],[20,[\"readonly\"]],[20,[\"title\"]],[20,[\"elementClass\"]],[20,[\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/em-checkbox-input.hbs"
    }
  });

  _exports.default = _default;
});