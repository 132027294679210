define('ui-slider/components/ui-slider', ['exports', 'ember', 'ui-slider/templates/components/ui-slider'], function (exports, _ember, _uiSliderTemplatesComponentsUiSlider) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var keys = Object.keys;
  var create = Object.create;
  // jshint ignore:line
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var $ = _ember['default'].$;
  var A = _ember['default'].A;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var typeOf = _ember['default'].typeOf;
  var debug = _ember['default'].debug;
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  // jshint ignore:line
  var snake = function snake(thingy) {
    return thingy ? _ember['default'].String.underscore(thingy) : thingy;
  };

  var numericApiSurface = ['min', 'max', 'step', 'precision', 'ticksSnapBounds'];
  var booleanApiSurface = ['range', 'tooltipSplit', 'ticksTooltip', 'reversed', 'enabled', 'naturalArrowKeys', 'focus'];
  var stringApiSurface = ['selection', 'tooltip', 'tooltipSeparator', 'tooltipPosition', 'selection', 'handle', 'scale', 'orientation'];
  var arrayApiSurface = ['ticks', 'ticksPositions', 'ticksLabels'];
  var functionalApiSurface = ['formatter'];
  var apiSurface = [].concat(numericApiSurface, booleanApiSurface, stringApiSurface, arrayApiSurface, functionalApiSurface);
  var assign = function assign() {
    var target = {};

    for (var i = 0; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (source.hasOwnProperty(key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };
  var MINALIMAL_VALUE = 'min_value_exceeded';
  var MAXIMAL_VALUE = 'max_value_exceeded';

  exports['default'] = _ember['default'].Component.extend({
    layout: _uiSliderTemplatesComponentsUiSlider['default'],
    classNames: ['ui-slider'],
    classNameBindings: ['isSliding:sliding', '_mood', '_section', 'range:range:singular', 'fill:fill:fit'],
    // API Surface (defaults)
    min: 1,
    max: 10,
    step: 1,
    precision: 0,
    fill: true,
    orientation: 'horizontal',
    range: false,
    selection: 'before', // values are 'before', 'after', or 'none' and indicate placement
    tooltip: 'show', // show, hide, or always
    tooltipSeparator: ':', // used in ranges
    tooltipPosition: 'top',
    tooltipSplit: false, // if false only one tooltip for ranges, if true then tooltips for both
    ticksTooltip: false,
    handle: 'round', // values are round, square, triangle, or custom
    reversed: false,
    enabled: _ember['default'].computed("disabled", {
      get: function get() {
        return !this.get("disabled");
      },
      set: function set(key, value) {
        return value;
      }
    }),
    naturalArrowKeys: false,
    scale: 'linear',
    focus: false,
    ticks: [],
    ticksPositions: [],
    ticksLabels: [],
    ticksSnapBounds: 0,
    // VALUE
    keepInRange: true,
    immediateResponse: false,
    _immediateResponse: on('init', observer('immediateResponse', function () {
      var _this = this;

      var immediateResponse = this.get('immediateResponse');
      var self = this;
      if (immediateResponse) {
        _ember['default'].run.schedule('afterRender', function () {
          _this._slider.on('slide', function (evt) {
            _ember['default'].run.debounce(function () {
              self.set('value', evt.value);
            }, 30);
          });
        });
      } else {
        if (this._slider) {
          this._slider.off('slide');
        }
      }
    })),
    sections: null,
    _oldSection: null,
    _section: computed('value', 'sections', function () {
      var _getProperties = this.getProperties('_oldSection');

      var _oldSection = _getProperties._oldSection;

      var newSection = this.sectionCalculator();

      if (newSection && newSection !== _oldSection) {
        this.sendAction('action', 'section-change', {
          context: this,
          section: newSection,
          oldSection: _oldSection
        });
        this.set('_oldSection', newSection);
      }

      return newSection ? 'section-' + newSection : null;
    }),
    sectionCalculator: function sectionCalculator() {
      var _getProperties2 = this.getProperties('sections', 'min', 'max', 'value');

      var sections = _getProperties2.sections;
      var min = _getProperties2.min;
      var max = _getProperties2.max;
      var value = _getProperties2.value;

      if (!sections || new A(['null', 'undefined']).includes(value)) {
        return null;
      }
      var section = 1;
      if (typeOf(sections) === 'array') {
        sections.map(function (item, index) {
          if (item > min && item < max && value > item) {
            section = index + 2;
          }
        });
      } else {
        var width = max - min + 1;
        var sectionWidth = width / Number(sections);
        section = Math.floor((value - min) / sectionWidth) + 1;
      }

      return section;
    },
    _value: observer('value', 'min', 'max', 'step', function () {
      this._valueObserver();
    }),
    mood: null,
    _mood: computed('mood', function () {
      var mood = this.get('mood');
      return mood ? 'mood-' + mood : null;
    }),
    _valueObserver: function _valueObserver() {
      var _this2 = this;

      run.next(function () {
        var _getProperties3 = _this2.getProperties('value', 'min', 'max', 'range');

        var value = _getProperties3.value;
        var min = _getProperties3.min;
        var max = _getProperties3.max;
        var range = _getProperties3.range;

        var controlValue = _this2._slider.slider('getValue');

        if (JSON.stringify(value) !== JSON.stringify(controlValue)) {
          // regardless of whether range or not process as an array
          value = typeOf(value) === 'array' ? value : [value];
          value = value.map(function (v) {
            v = v < min ? _this2.handleMinimalValue() : v;
            v = v > max ? _this2.handleMaximalValue() : v;

            return v;
          });
          // now convert back to scalar if appropriate
          value = range ? value : value[0];
          _this2.setValue(value);

          _this2.sendAction('action', 'value-sync', {
            context: _this2,
            value: value,
            message: 'A new value -- ' + value + ' -- was received by container and pushed into slider UI'
          });
        }
      });
    },

    handleMinimalValue: function handleMinimalValue() {
      var _this3 = this;

      var _getProperties4 = this.getProperties('min', 'value', 'keepInRange');

      var min = _getProperties4.min;
      var value = _getProperties4.value;
      var keepInRange = _getProperties4.keepInRange;

      this.sendAction('error', MINALIMAL_VALUE, {
        message: 'The minimum value [' + min + '] was exceeded: ' + value,
        context: this
      });
      if (keepInRange) {
        run.next(function () {
          _this3.set('value', min);
          _this3.sendAction('action', 'range-correction', {
            context: _this3,
            message: 'The value was less than the minimum so resetting value to minimum [' + min + ']',
            value: min,
            oldValue: value
          });
        });
      }

      return min;
    },
    handleMaximalValue: function handleMaximalValue() {
      var _this4 = this;

      var _getProperties5 = this.getProperties('max', 'value', 'keepInRange');

      var max = _getProperties5.max;
      var value = _getProperties5.value;
      var keepInRange = _getProperties5.keepInRange;

      this.sendAction('error', MAXIMAL_VALUE, {
        message: 'The maximum value [' + max + '] was exceeded: ' + value,
        context: this
      });
      if (keepInRange) {
        run.next(function () {
          _this4.set('value', max);
          _this4.sendAction('action', 'range-correction', {
            context: _this4,
            message: 'The value was less than the minimum so resetting value to minimum [' + max + ']',
            value: max,
            oldValue: value
          });
        });
      }

      return max;
    },

    // Functions
    disabled: false,
    _disabled: observer('disabled', function () {
      var _this5 = this;

      var disabled = this.get('disabled');
      run.next(function () {
        if (disabled) {
          _this5._slider.slider('disable');
          _this5.sendAction('action', 'slide-disabled', {
            context: _this5,
            value: _this5.get('value')
          });
        } else {
          _this5._slider.slider('enable');
          _this5.sendAction('action', 'slide-enabled', {
            context: _this5,
            value: _this5.get('value')
          });
        }
      });
    }),
    // Configuration Changes
    _configObserver: observer.apply(undefined, _toConsumableArray(apiSurface).concat([function () {
      this.updateConfig();
    }])),
    updateConfig: function updateConfig() {
      var _this6 = this;

      var changedConfig = apiSurface.filter(function (item) {
        return _this6[item] !== _this6._benchmark[item];
      });
      changedConfig.map(function (item) {
        _this6._slider.slider('setAttribute', snake(item), get(_this6, item));
        _this6.sendAction('action', 'set-attribute', { context: _this6, property: item, value: get(_this6, item) });
      });
      this._benchmarkConfig();
      this._slider.slider('refresh');
      this.setValue(this.get('value'));
    },
    _benchmarkConfig: function _benchmarkConfig() {
      this._benchmark = this.getProperties(apiSurface);
    },
    getConfiguration: function getConfiguration() {
      var _this7 = this;

      var options = {};
      numericApiSurface.map(function (item) {
        options[snake(item)] = Number(_this7.get(item));
        return item;
      });
      booleanApiSurface.map(function (item) {
        options[snake(item)] = Boolean(_this7.get(item));
        return item;
      });
      arrayApiSurface.map(function (item) {
        var data = _this7.get(item);
        if (typeOf(data) === 'string') {
          data = data.split(',');
          data = data.map(function (d) {
            return isNaN(Number(d)) ? d : Number(d);
          });
        }
        if (data) {
          options[snake(item)] = data;
        }
        return item;
      });
      stringApiSurface.map(function (item) {
        options[snake(item)] = _this7.get(item);
        return item;
      });
      functionalApiSurface.map(function (item) {
        options[snake(item)] = _this7.get(item);
      });

      return options;
    },
    initializeJqueryComponent: function initializeJqueryComponent() {
      var elementId = this.get('elementId');
      var options = this.getConfiguration();
      var value = this.get('value');
      if (typeOf(value) === 'string') {
        value = isNaN(Number(value)) ? options.min : Number(value);
      }

      options = assign(options, { value: value });
      this._slider = this.$('#slider-value-' + elementId).slider(options);
    },
    addEventListeners: function addEventListeners() {
      var self = this;
      self._slider.on('slideStart', function (evt) {
        self.set('isSliding', true);
        evt.preventDefault();
        run.next(function () {
          self.sendAction('action', 'slideStart', { context: self, value: evt.value, evt: evt });
        });
      });
      self._slider.on('slideStop', function (evt) {
        self.set('isSliding', false);
        evt.preventDefault();
        self.sendAction('action', 'slideStop', { context: self, value: evt.value, evt: evt });
        self.sendAction('changed', evt.value, { context: self, evt: evt, oldValue: self.get('value') });
        self.set('value', evt.value);
      });
    },
    destroyJqueryComponent: function destroyJqueryComponent() {
      this._slider.slider('destroy');
    },
    setDefaultValue: function setDefaultValue() {
      var _getProperties6 = this.getProperties('defaultValue', 'value');

      var defaultValue = _getProperties6.defaultValue;
      var value = _getProperties6.value;

      if (new A(['null', 'undefined']).includes(typeOf(value))) {
        defaultValue = typeOf(defaultValue) === 'string' && defaultValue.split(',').length > 1 ? defaultValue.split(',') : defaultValue;
        this.set('value', defaultValue);
      }
    },
    ensureValueSynced: function ensureValueSynced() {
      var _getProperties7 = this.getProperties('value');

      var value = _getProperties7.value;

      if (!value) {
        this.set('value', this.getValue());
      }
    },
    getValue: function getValue() {
      return this._slider.slider('getValue');
    },
    setValue: function setValue(value) {
      this._slider.slider('setValue', value);
    },

    // LIFECYCLE HOOKS
    init: function init() {
      var _this8 = this;

      this._super.apply(this, arguments);
      if (!this.get('elementId')) {
        this.set('elementId', 'ember-' + Math.random().toString(36).substr(2, 9));
      }

      run.schedule('afterRender', function () {
        _this8.initializeJqueryComponent();
        _this8.addEventListeners();
        _this8.setDefaultValue();
        _this8.ensureValueSynced(); // if no default value and value set then we need to get value from the control
        _this8._benchmarkConfig();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.destroyJqueryComponent();
    }
  });
});