define("ember-date-components/components/date-picker-inline", ["exports", "ember-date-components/components/date-picker", "ember-date-components/templates/components/date-picker-inline"], function (_exports, _datePicker, _datePickerInline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datePicker.default.extend({
    layout: _datePickerInline.default
  });

  _exports.default = _default;
});