define('ember-cli-jstree/mixins/ember-jstree-actions', ['exports', 'ember'], function (exports, _ember) {

    /**
    * Actions that are mapped to jstree and return Ember actions with parameters where necessary.
    * These actions are passed to the addon via the actionReceiver property and are generally
    * the same as jsTree method names, but camelized.
    *
    * @class EmberJstreeActions
    */
    exports['default'] = _ember['default'].Mixin.create({

        _jsTreeFindNodeMatches: function _jsTreeFindNodeMatches(property, values) {
            var treeObject = this.get('treeObject');
            var nodes = [];

            if ('id' === property) {
                // If property is ID, can use get_node, which is faster than search.
                if (_ember['default'].isArray(values)) {
                    for (var i = 0; i < values.length; i++) {
                        var node = treeObject.jstree(true).get_node(values[i]);
                        nodes.push(node);
                    }
                }
            } else {
                if (!_ember['default'].isArray(values)) {
                    values = _ember['default'].A([values]);
                }

                var data = treeObject.jstree(true)._model.data;
                var dataKeys = Object.keys(data);

                for (var i = 0; i < values.length; i++) {
                    var value = values[i];
                    if (!_ember['default'].isNone(value)) {
                        for (var j = 0; j < dataKeys.length; j++) {
                            var node = data[dataKeys[j]];
                            if (_ember['default'].typeOf(node.original) !== 'undefined' && node.original[property] === value) {
                                nodes.push(node);
                                break;
                            }
                        }
                    }
                }
            }

            return nodes;
        },

        actions: {

            redraw: function redraw() {
                // Redraw true currently does not work as intended. Need to investigate.
                this._refreshTree();
            },

            destroy: function destroy() {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    if (!_ember['default'].testing && !this.get('_isDestroying')) {
                        treeObject.jstree(true).destroy();
                    }

                    this.sendAction('eventDidDestroy');
                }
            },

            getNode: function getNode(nodeId) {
                if (_ember['default'].typeOf(nodeId) !== 'string') {
                    throw new Error('getNode() requires a node ID to be passed to it to return the node!');
                }

                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionGetNode', treeObject.jstree(true).get_node(nodeId));
                }
            },

            getText: function getText(obj) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionGetText', treeObject.jstree(true).get_text(obj));
                }
            },

            getPath: function getPath(obj, glue, ids) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionGetPath', treeObject.jstree(true).get_path(obj, glue, ids));
                }
            },

            getChildrenDom: function getChildrenDom(obj) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionGetChildrenDom', treeObject.jstree(true).get_children_dom(obj));
                }
            },

            getContainer: function getContainer() {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionGetContainer', treeObject.jstree(true).get_container());
                }
            },

            getParent: function getParent(obj) {
                obj = obj || "#";
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionGetParent', treeObject.jstree(true).get_parent(obj));
                }
            },

            loadNode: function loadNode(obj, cb) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionLoadNode', treeObject.jstree(true).load_node(obj, cb));
                }
            },

            loadAll: function loadAll(obj, cb) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionLoadAll', treeObject.jstree(true).load_all(obj, cb));
                }
            },

            openNode: function openNode(obj, cb, animation) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).open_node(obj, cb, animation);
                }
            },

            openAll: function openAll(obj, animation) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).open_all(obj, animation);
                }
            },

            closeNode: function closeNode(obj, cb) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).close_node(obj, cb);
                }
            },

            closeAll: function closeAll(obj, animation) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).close_all(obj, animation);
                }
            },

            toggleNode: function toggleNode(obj) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).toggle_node(obj);
                }
            },

            createNode: function createNode(obj, node, pos, callback, is_loaded) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionCreateNode', treeObject.jstree(true).create_node(obj, node, pos, callback, is_loaded));
                }
            },

            renameNode: function renameNode(obj, val) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionRenameNode', treeObject.jstree(true).rename_node(obj, val));
                }
            },

            moveNode: function moveNode(obj, par, pos, callback, is_loaded) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).move_node(obj, par, pos, callback, is_loaded);
                }
            },

            copyNode: function copyNode(obj, par, pos, callback, is_loaded) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).copy_node(obj, par, pos, callback, is_loaded);
                }
            },

            deleteNode: function deleteNode(obj) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    this.sendAction('actionDeleteNode', treeObject.jstree(true).delete_node(obj));
                }
            },

            selectNode: function selectNode(obj, suppress_event) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).select_node(obj, suppress_event);
                }
            },

            deselectNode: function deselectNode(obj, suppress_event) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).deselect_node(obj, suppress_event);
                }
            },

            selectAll: function selectAll(suppress_event) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).select_all(suppress_event);
                }
            },

            deselectAll: function deselectAll(suppress_event) {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    treeObject.jstree(true).deselect_all(suppress_event);
                }
            },

            lastError: function lastError() {
                var treeObject = this.get('treeObject');
                if (!_ember['default'].isNone(treeObject)) {
                    var e = treeObject.jstree(true).last_error();
                    this.set('_lastError', e);
                    this.sendAction('actionLastError', e);
                }
            },

            deselectNodes: function deselectNodes(property, values) {
                if (arguments.length === 0) {
                    _ember['default'].warn('Using deselectNodes without parameters to deselect all nodes is deprecated. Use the deselectAll action to deselect all nodes.');
                    this.send('deselectAll');
                    return;
                }

                var treeObject = this.get('treeObject');
                var nodes = this._jsTreeFindNodeMatches(property, values);

                treeObject.jstree(true).deselect_node(nodes, true, true);
                treeObject.jstree(true).redraw(); // Redraw so that parent nodes get their indicator changed.
            },

            selectNodes: function selectNodes(property, values) {
                var treeObject = this.get('treeObject');
                if (null !== treeObject && !this.get('isDestroyed') && !this.get('isDestroying')) {
                    var nodes = this._jsTreeFindNodeMatches(property, values);
                    treeObject.jstree(true).select_node(nodes, true, true);
                }
            }
        }
    });
});