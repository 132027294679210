define('ember-cli-d3/system/selection-proxy', ['exports', 'ember', 'd3', 'ember-cli-d3/utils/d3'], function (exports, _ember, _d3, _emberCliD3UtilsD3) {

  var SelectionProxy = _ember['default'].Object.extend({
    unknownProperty: function unknownProperty(key) {
      return SelectionProxy.proxyElement(this, 'g', key);
    },

    transition: function transition(options) {
      var ret = TransitionSelectionProxy.create({});

      ret._selection = this.get('selection');
      ret._options = options;

      return ret;
    },

    call: function call(component) {
      if (!component.isDestroying) {
        return component.get('call').call(component, this.get('selection'));
      }
    },

    toString: function toString() {
      var guid = _ember['default'].guidFor(this);
      var selection = this.get('_selection');
      var node = (SelectionProxy.detectInstance(selection) ? selection.get('selection') : selection).node();
      var tagName = node.tagName;
      var id = node.id ? '#' + node.id : '';
      var cls = node.classList && node.classList[0] ? '.' + node.classList[0] : '';

      return '<ember-cli-d3@selection-proxy:' + guid + '::' + tagName + id + cls + '>';
    }
  });

  SelectionProxy.reopenClass({
    proxyElement: function proxyElement(proxy, tagName, className) {
      var selection = proxy.get('selection');
      var proxied = selection.select(tagName + '.' + className);

      if (proxied.empty()) {
        proxied = selection.append(tagName).classed(className, true);
      }

      return SelectionProxy.create({ selection: proxied });
    }
  });

  var TransitionSelectionProxy = SelectionProxy.extend({
    _selection: null,
    _options: null,

    selection: _ember['default'].computed(function () {
      return (0, _emberCliD3UtilsD3.assign)(this._selection.transition(), this._options);
    }).volatile()

  });

  exports['default'] = _ember['default'].Object.extend({
    container: null,

    select: _ember['default'].computed('element', function () {
      return SelectionProxy.create({
        selection: _d3['default'].select(this.get('element'))
      });
    }).readOnly(),
    defs: _ember['default'].computed('element', function () {
      var element = this.get('element');
      var node = element.firstChild;

      _ember['default'].assert('selection-proxy#defs shouldnt be called second time', node.nodeType === Node.COMMENT_NODE && node.textContent === 'defs');

      var defs = document.createElementNS(_d3['default'].ns.prefix.svg, 'defs');

      element.replaceChild(defs, node);

      return SelectionProxy.create({
        selection: _d3['default'].select(defs)
      });
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('container', document.createDocumentFragment());
    },

    swapContainer: function swapContainer() {
      var _this = this;

      var fragment = this.get('container');
      var svg = this.get('element').querySelector('svg');

      svg.appendChild(fragment);

      _ember['default'].run.schedule('sync', function () {
        _this.set('container', svg);
        _this.set('select.selection', _d3['default'].select(svg));
      });
    }
  });
});