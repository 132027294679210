define("ember-rapid-forms/templates/components/em-custom-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nvmA8ePs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"em-form-group\",null,[[\"model\",\"property\",\"inputComponent\"],[[20,[\"model\"]],[20,[\"property\"]],[19,0,[]]]],{\"statements\":[[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/em-custom-input.hbs"
    }
  });

  _exports.default = _default;
});