define("ember-crumbly/templates/components/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SBDq9pBE",
    "block": "{\"symbols\":[\"route\",\"&default\"],\"statements\":[[4,\"each\",[[20,[\"routeHierarchy\"]]],null,{\"statements\":[[4,\"if\",[[22,2]],null,{\"statements\":[[0,\"    \"],[11,2,[[19,0,[]],[19,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"bread-crumb\",null,[[\"route\",\"breadCrumbs\"],[[19,1,[]],[19,0,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-crumbly/templates/components/bread-crumbs.hbs"
    }
  });

  _exports.default = _default;
});