define("ember-data-has-many-query/mixins/rest-adapter", ["exports", "ember-copy", "ember-data-has-many-query/property-names"], function (_exports, _emberCopy, _propertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var evaluateFunctions = function evaluateFunctions(object, record) {
    if (Ember.isArray(object)) {
      object.forEach(function (element) {
        if (_typeof(element) === 'object') {
          evaluateFunctions(element, record);
        }
      });
    } else if (!Ember.isNone(object)) {
      Object.keys(object).forEach(function (key) {
        if (!object.hasOwnProperty(key)) {
          return;
        }

        var value = object[key];

        if (typeof value === 'function') {
          object[key] = value.apply(record);
        } else if (_typeof(value) === 'object') {
          evaluateFunctions(value, record);
        }
      });
    }
  };
  /**
   * Mixin for DS.RESTAdapter.
   */


  var _default = Ember.Mixin.create({
    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      var id = snapshot.id;
      var type = snapshot.modelName;
      url = this.urlPrefix(url, this.buildURL(type, id, null, 'findHasMany'));
      var query = this.buildRelationshipQuery(snapshot, relationship);
      var options = {
        data: query
      };
      snapshot.record.set((0, _propertyNames.ajaxOptionsPropertyName)(relationship.key), options);
      return this.ajax(url, 'GET', options);
    },
    findBelongsTo: function findBelongsTo(store, snapshot, url, relationship) {
      var id = snapshot.id;
      var type = snapshot.modelName;
      url = this.urlPrefix(url, this.buildURL(type, id, null, 'findBelongsTo'));
      var query = this.buildRelationshipQuery(snapshot, relationship);
      var options = {
        data: query
      };
      snapshot.record.set((0, _propertyNames.ajaxOptionsPropertyName)(relationship.key), options);
      return this.ajax(url, 'GET', options);
    },
    buildRelationshipQuery: function buildRelationshipQuery(snapshot, relationship) {
      var data = {}; //add query parameters from the model mixin's query function

      var queryParams = snapshot.record.get((0, _propertyNames.queryParamPropertyName)(relationship.key));

      if (!Ember.isEmpty(queryParams)) {
        data = (0, _emberCopy.copy)(queryParams, true);
      } //add query parameters defined in the model itself by the 'parameters' option


      var relationshipParams = relationship.options.parameters;

      if (!Ember.isEmpty(relationshipParams)) {
        Ember.assign(data, relationshipParams);
      } //replace any functions in the data with their return value


      evaluateFunctions(data, snapshot.record);
      return data;
    },
    ajaxOptions: function ajaxOptions() {
      var ajaxOptions = this._super.apply(this, arguments);

      var defaultBeforeSend = ajaxOptions.beforeSend || function () {};

      ajaxOptions.beforeSend = function (jqXHR) {
        defaultBeforeSend.apply(void 0, arguments); //store the jqXHR in the options object, which in turn is stored in the model itself, so the model mixin can abort it

        ajaxOptions.jqXHR = jqXHR;
      };

      return ajaxOptions;
    }
  });

  _exports.default = _default;
});