define('ember-rapid-forms/mixins/has-property-validation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      (false && !(!Ember.isNone(this.get('propertyName'))) && Ember.assert('propertyName is required.', !Ember.isNone(this.get('propertyName'))));

      Ember.defineProperty(this, 'errors', Ember.computed.alias('model.errors.' + this.get('propertyName')));
    },


    status: Ember.computed('errors.length', {
      get: function get() {
        if (this.get('errors.length')) {
          return 'error';
        } else {
          return 'success';
        }
      }
    })
  });
});