define('ember-cli-d3/utils/d3', ['exports', 'ember', 'd3'], function (exports, _ember, _d3) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.guid = guid;
  exports.accessor = accessor;
  exports.assign = assign;
  exports.translateX = translateX;
  exports.rotate = rotate;
  exports.join = join;

  var noop = _d3['default'].functor();

  var guidCounter = 0;

  function guid() {
    return 'ember-cli-d3-' + guidCounter++;
  }

  function accessor(path) {
    if (typeof path === 'function') {
      return path;
    }

    var deep = ~path.indexOf('.');

    return function (data) {
      return deep ? _ember['default'].get(data, path) : data[path];
    };
  }

  function assign(target, values) {
    var key;

    for (key in values) {
      if (typeof target[key] === 'function') {
        target[key](values[key]);
      }
    }

    return target;
  }

  function translateX(fn) {
    fn = _d3['default'].functor(fn);

    return function translate() {
      return 'translate(' + fn.apply(this, arguments) + ' 0)';
    };
  }

  function rotate(fn) {
    fn = _d3['default'].functor(fn);

    return function rotate() {
      return 'rotate(' + fn.apply(this, arguments) + ')';
    };
  }

  // TODO allow inlined data
  join.parseDataExpr = function (expr) {
    if (typeof expr === 'string') {
      var _expr$match = expr.match(/([\w\.]+)(\[([\w\.]+)\])?/);

      var _expr$match2 = _slicedToArray(_expr$match, 4);

      var dataPath = _expr$match2[1];
      var keyPath = _expr$match2[3];

      keyPath = keyPath || null;
      dataPath = dataPath || null;

      return { dataPath: dataPath, keyPath: keyPath };
    } else {
      return { inlineData: expr };
    }
  };

  // TODO allow cssExpr to contain attributes
  join.parseCssExpr = function (expr) {
    var _expr$split = expr.split('.');

    var _expr$split2 = _slicedToArray(_expr$split, 2);

    var tagName = _expr$split2[0];
    var cssName = _expr$split2[1];

    tagName = tagName || 'g';

    return { tagName: tagName, cssName: cssName };
  };

  function join(dataExpr, cssExpr, _ref) {
    var update = _ref.update;
    var enter = _ref.enter;
    var exit = _ref.exit;

    var _join$parseDataExpr = join.parseDataExpr(dataExpr);

    var inlineData = _join$parseDataExpr.inlineData;
    var dataPath = _join$parseDataExpr.dataPath;
    var keyPath = _join$parseDataExpr.keyPath;

    var _join$parseCssExpr = join.parseCssExpr(cssExpr);

    var tagName = _join$parseCssExpr.tagName;
    var cssName = _join$parseCssExpr.cssName;

    function visual(sel) {
      for (var _len = arguments.length, parentData = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        parentData[_key - 1] = arguments[_key];
      }

      var context = this;
      var data = [].concat(inlineData || this.get(dataPath) || []);
      var key = keyPath && this.get(keyPath);

      if (keyPath) {
        key = this.get(keyPath);
      }
      if (typeof key === 'string') {
        key = accessor(key);
      }

      var bind = function bind(joined) {
        return function (sel) {
          return joined.apply(context, [sel].concat(parentData));
        };
      };

      var updateSel = sel.selectAll(cssExpr).data(data, key);
      var enterSel = updateSel.enter();
      var exitSel = updateSel.exit();

      enterSel.call(bind(visual.enter));
      updateSel.call(bind(visual.update));
      exitSel.call(bind(visual.exit));

      return updateSel;
    }

    visual.enter = enter || function (sel) {
      return sel.append(tagName).classed(cssName, true);
    };
    visual.exit = exit || function (sel) {
      return sel.remove();
    };
    visual.update = update || noop;

    return visual;
  }
});