define('ember-rapid-forms/utils/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    createBoundSwitchAccessor: function createBoundSwitchAccessor(switchValue, myProperty, myDefault) {
      if (myDefault === null) {
        myDefault = 'default';
      }
      return Ember.computed(myProperty, {
        get: function get() {
          return this.get(myProperty) === switchValue;
        },
        set: function set(key, value) {
          this.set(myProperty, value ? switchValue : myDefault);
        }
      });
    }
  };
});