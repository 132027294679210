define("ember-date-components/templates/components/date-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0zUzJpHi",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"date-time-picker__item date-time-picker__item--date\"],[7],[0,\"\\n  \"],[1,[25,\"date-picker\",null,[[\"value\",\"buttonClasses\",\"buttonDateFormat\",\"minDate\",\"maxDate\",\"disabled\",\"renderInPlace\",\"horizontalPosition\",\"verticalPosition\",\"action\"],[[20,[\"value\"]],[20,[\"datePickerClasses\"]],[20,[\"buttonDateFormat\"]],[20,[\"minDate\"]],[20,[\"maxDate\"]],[20,[\"disabled\"]],[20,[\"renderInPlace\"]],[20,[\"horizontalPosition\"]],[20,[\"verticalPosition\"]],[25,\"action\",[[19,0,[]],\"updateDate\"],null]]]],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"span\"],[9,\"class\",\"date-time-picker__item date-time-picker__item--time\"],[7],[0,\"\\n  \"],[1,[25,\"time-picker\",null,[[\"value\",\"disabled\",\"minTime\",\"maxTime\",\"step\",\"selectStep\",\"amPm\",\"inputClasses\",\"renderInPlace\",\"horizontalPosition\",\"verticalPosition\",\"action\"],[[20,[\"timePickerValue\"]],[20,[\"timePickerDisabled\"]],[20,[\"minTime\"]],[20,[\"maxTime\"]],[20,[\"step\"]],[20,[\"selectStep\"]],[20,[\"amPm\"]],[20,[\"timePickerClasses\"]],[20,[\"renderInPlace\"]],[20,[\"horizontalPosition\"]],[20,[\"verticalPosition\"]],[25,\"action\",[[19,0,[]],\"updateTime\"],null]]]],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-time-picker.hbs"
    }
  });

  _exports.default = _default;
});