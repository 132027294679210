define('ember-models-table/components/models-table', ['exports', 'ember', 'ember-models-table/utils/fmt'], function (exports, _ember, _emberModelsTableUtilsFmt) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /**
   * @typedef {object} ModelsTable~ModelsTableColumn
   * @property {string} propertyName data's property shown in the current column
   * @property {string} title column's title
   * @property {string} template custom template used in the column's cells
   * @property {string} sortedBy custom data's property that is used to sort column
   * @property {string} sortDirection the default sorting direction of the column, asc or desc - only in effect if sortPrecedence is set!
   * @property {number} sortPrecedence the sort presedence for this column - needs to be larger than -1 for sortDirection to take effect
   * @property {boolean} disableSorting if sorting should be disabled for this column
   * @property {boolean} disableFiltering if filtering should be disabled for this column
   * @property {string} filterString a default filtering for this column
   * @property {string} filteredBy custom data's property that is used to filter column
   * @property {string} sorting is column sorted now
   * @property {boolean} isHidden is column hidden now
   * @property {boolean} mayBeHidden may this column be hidden
   * @property {boolean} filterWithSelect should select-box be used as filter for this column
   * @property {string[]|number[]} predefinedFilterOptions list of option to the filter-box (used if <code>filterWithSelect</code> is true)
   * @property {string} className custom classnames for column
   * @property {function} filterFunction custom function used to filter rows (used if <code>filterWithSelect</code> is false)
   */

  /**
   * @typedef {object} groupedHeader
   * @property {string} title header for grouped columns
   * @property {number} colspan HTML colspan attr
   * @property {number} rowspan HTML rowspan attr
   */

  var keys = Object.keys;

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var getWithDefault = _ember['default'].getWithDefault;
  var setProperties = _ember['default'].setProperties;
  var getProperties = _ember['default'].getProperties;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var isNone = _ember['default'].isNone;
  var A = _ember['default'].A;
  var on = _ember['default'].on;
  var defineProperty = _ember['default'].defineProperty;
  var compare = _ember['default'].compare;
  var typeOf = _ember['default'].typeOf;
  var run = _ember['default'].run;
  var Component = _ember['default'].Component;
  var assert = _ember['default'].assert;
  var S = _ember['default'].String;
  var O = _ember['default'].Object;
  var jQ = _ember['default'].$;

  var NOT_SORTED = -1;

  var defaultMessages = {
    searchLabel: 'Search:',
    searchPlaceholder: '',
    'columns-title': 'Columns',
    'columns-showAll': 'Show All',
    'columns-hideAll': 'Hide All',
    'columns-restoreDefaults': 'Restore Defaults',
    tableSummary: 'Show %@ - %@ of %@',
    allColumnsAreHidden: 'All columns are hidden. Use <strong>columns</strong>-dropdown to show some of them',
    noDataToShow: 'No records to show'
  };

  var defaultIcons = {
    'sort-asc': 'glyphicon glyphicon-triangle-bottom',
    'sort-desc': 'glyphicon glyphicon-triangle-top',
    'column-visible': 'glyphicon glyphicon-check',
    'column-hidden': 'glyphicon glyphicon-unchecked',
    'nav-first': 'glyphicon glyphicon-chevron-left',
    'nav-prev': 'glyphicon glyphicon-menu-left',
    'nav-next': 'glyphicon glyphicon-menu-right',
    'nav-last': 'glyphicon glyphicon-chevron-right',
    'caret': 'caret'
  };

  var defaultCssClasses = {
    outerTableWrapper: 'models-table-wrapper',
    innerTableWrapper: 'inner-table-wrapper',
    table: 'table table-striped table-bordered table-condensed',
    globalFilterWrapper: 'pull-left',
    columnsDropdownWrapper: 'pull-right columns-dropdown',
    columnsDropdownButtonWrapper: 'btn-group',
    columnsDropdown: 'dropdown-menu pull-right',
    theadCell: 'table-header',
    theadCellNoSorting: 'table-header-no-sorting',
    theadCellNoFiltering: 'table-header-no-filtering',
    tfooterWrapper: 'table-footer clearfix',
    footerSummary: 'table-summary',
    footerSummaryNumericPagination: 'col-md-3 col-sm-3',
    footerSummaryDefaultPagination: 'col-md-8 col-sm-8',
    pageSizeWrapper: 'col-md-2 col-sm-2',
    pageSizeSelectWrapper: 'pull-right',
    paginationWrapper: 'table-nav',
    paginationWrapperNumeric: 'col-md-7 col-sm-7',
    paginationWrapperDefault: 'col-md-2 col-sm-2',
    buttonDefault: 'btn btn-default',
    noDataCell: ''
  };

  function isSortedByDefault(column) {
    return column.sortPrecedence > NOT_SORTED;
  }

  /**
   * Extend <code>customs</code>-object with properties from <code>defaults</code>-object
   *
   * @param {object} customs
   * @param {object} defaults
   * @returns {object}
   */
  function smartExtend(customs, defaults) {
    var result = {};
    keys(customs).forEach(function (k) {
      set(result, k, get(customs, k));
    });

    keys(defaults).forEach(function (k) {
      if (isNone(get(result, k))) {
        set(result, k, get(defaults, k));
      }
    });
    return result;
  }

  /**
   * Default filter-function used in the filter by columns
   *
   * @param {string} cellValue value in the table cell
   * @param {string} filterString needed substring
   * @returns {boolean}
   */
  function defaultFilter(cellValue, filterString) {
    return -1 !== cellValue.indexOf(filterString);
  }

  /**
   * data -> filteredContent -> arrangedContent -> visibleContent
   *
   * @class ModelsTable
   * @extends Ember.Component
   */
  exports['default'] = Component.extend({

    /**
     * Number of records shown on one table-page (size of the <code>visibleContent</code>)
     *
     * @type number
     * @name ModelsTable#pageSize
     * @default 10
     */
    pageSize: 10,

    /**
     * @type {number}
     * @name ModelsTable#currentPageNumber
     * @default 1
     */
    currentPageNumber: 1,

    /**
     * @type {string[]}
     * @name ModelsTable#sortProperties
     * @default []
     */
    sortProperties: A([]),

    /**
     * Determines if multi-columns sorting should be used
     *
     * @type {boolean}
     * @name ModelsTable#multipleColumnsSorting
     * @default false
     */
    multipleColumnsSorting: true,

    /**
     * Determines if table footer should be shown on the page
     *
     * @type {boolean}
     * @name ModelsTable#showTableFooter
     * @default true
     */
    showTableFooter: true,

    /**
     * Determines if numeric pagination should be used
     *
     * @type {boolean}
     * @name ModelsTable#useNumericPagination
     * @default false
     */
    useNumericPagination: false,

    /**
     * Determines if columns-dropdown should be shown
     *
     * @type {boolean}
     * @name ModelsTable#showColumnsDropdown
     * @default true
     */
    showColumnsDropdown: true,

    /**
     * Determines if filtering by columns should be available to the user
     *
     * @type {boolean}
     * @name ModelsTable#useFilteringByColumns
     * @default true
     */
    useFilteringByColumns: true,

    /**
     * @type {string}
     * @name ModelsTable#filterString
     * @default ''
     */
    filterString: '',

    /**
     * Determines if filtering (global and by column) should ignore case
     *
     * @type {boolean}
     * @name ModelsTable#filteringIgnoreCase
     * @default false
     */
    filteringIgnoreCase: false,

    /**
     * Determines if filtering should be done by hidden columns
     * Notice: after changing this value filtering results will be updated only after filter options are changed
     *
     * @type {boolean}
     * @name ModelsTable#doFilteringByHiddenColumns
     * @default true
     */
    doFilteringByHiddenColumns: true,

    /**
     * Determines if "Global filter"-field should be shown
     *
     * @type {boolean}
     * @name ModelsTable#showGlobalFilter
     * @default true
     */
    showGlobalFilter: true,

    /**
     * Determines if focus should be on the "Global filter"-field on component render
     *
     * @type {boolean}
     * @name ModelsTable#focusGlobalFilter
     * @default false
     */
    focusGlobalFilter: false,

    /**
     * Determines if <code>processedColumns</code> will be updated if <code>columns</code> are changed (<code>propertyName</code> and
     * <code>template</code> are observed)
     * <b>IMPORTANT</b> All filter, sort and visibility options will be dropped to the default values while updating
     *
     * @type {boolean}
     * @name ModelsTable#columnsAreUpdateable
     * @default false
     */
    columnsAreUpdateable: false,

    /**
     * <code>columns</code> fields which are observed to update shown table-columns
     * It is used only if <code>columnsAreUpdateable</code> is <code>true</code>
     *
     * @type {string[]}
     * @name ModelsTable#columnFieldsToCheckUpdate
     * @default ['propertyName', 'template']
     */
    columnFieldsToCheckUpdate: A(['propertyName', 'template']),

    /**
     * All table records
     *
     * @type {Ember.Object[]}
     * @name ModelsTable#data
     * @default []
     */
    data: A([]),

    /**
     * Table columns
     *
     * @type {ModelsTable~ModelsTableColumn[]}
     * @name ModelsTable#columns
     * @default []
     */
    columns: A([]),

    /**
     * @type {Ember.Object[]}
     * @name ModelsTable#processedColumns
     * @default []
     */
    processedColumns: A([]),

    /**
     * @type {Object}
     * @name ModelsTable#messages
     */
    messages: O.create({}),

    /**
     * @type {Object}
     * @name ModelsTable#classes
     */
    classes: O.create({}),

    /**
     * @type {Object}
     * @name ModelsTable#icons
     */
    icons: O.create({}),

    /**
     * List of the additional headers
     * Used to group columns
     *
     * @type {groupedHeader[][]}
     * @name ModelsTable#groupedHeaders
     */
    groupedHeaders: A([]),

    /**
     * Template with First|Prev|Next|Last buttons
     *
     * @type {string}
     * @name ModelsTable#simplePaginationTemplate
     * @default 'components/models-table/simple-pagination'
     */
    simplePaginationTemplate: 'components/models-table/simple-pagination',

    /**
     * Template with nav buttons
     *
     * @type {string}
     * @name ModelsTable#numericPaginationTemplate
     * @default 'components/models-table/numeric-pagination'
     */
    numericPaginationTemplate: 'components/models-table/numeric-pagination',

    /**
     * Template with table footer
     *
     * @type {string}
     * @name ModelsTable#tableFooterTemplate
     * @default 'components/models-table/table-footer'
     */
    tableFooterTemplate: 'components/models-table/table-footer',

    /**
     * Template for page size
     *
     * @type {string}
     * @name ModelsTable#pageSizeTemplate
     * @default 'components/models-table/table-footer'
     */
    pageSizeTemplate: 'components/models-table/page-size',

    /**
     * Determines if page size should be shown
     *
     * @type {boolean}
     * @name ModelsTable#showPageSize
     * @default true
     */
    showPageSize: true,

    /**
     * Template with global filter
     *
     * @type {string}
     * @name ModelsTable#globalFilterTemplate
     * @default 'components/models-table/global-filter'
     */
    globalFilterTemplate: 'components/models-table/global-filter',

    /**
     * Template with columns dropdown
     *
     * @type {string}
     * @name ModelsTable#columnsDropdownTemplate
     * @default 'components/models-table/columns-dropdown'
     */
    columnsDropdownTemplate: 'components/models-table/columns-dropdown',

    /**
     * Template with header row for column names
     *
     * @type {string}
     * @name ModelsTable#headerRowSortingTemplate
     * @default 'components/models-table/header-row-sorting'
     */
    headerSortingRowTemplate: 'components/models-table/header-row-sorting',

    /**
     * Template for sorting icons
     *
     * @type {string}
     * @name ModelsTable#headerSortingIconsTemplate
     * @default 'components/models-table/header-sorting-icons'
     */
    headerSortingIconsTemplate: 'components/models-table/header-sorting-icons',

    /**
     * Template with header row for column filters
     *
     * @type {string}
     * @name ModelsTable#headerFilteringRowTemplate
     * @default 'components/models-table/header-row-filtering'
     */
    headerFilteringRowTemplate: 'components/models-table/header-row-filtering',

    /**
     * Template with header rows for columns grouping
     *
     * @type {string}
     * @name ModelsTable#headerFilteringRowTemplate
     * @default 'components/models-table/header-rows-grouped'
     */
    headerGroupedRowsTemplate: 'components/models-table/header-rows-grouped',

    /**
     * Template for table's row
     *
     * @type {string}
     * @default 'components/models-table/row'
     * @name ModelsTable#rowTemplate
     */
    rowTemplate: 'components/models-table/row',

    /**
     * Action-name sent on user interaction
     *
     * @type {string}
     * @default 'displayDataChanged'
     * @name ModelsTable#displayDataChangedAction
     */
    displayDataChangedAction: 'displayDataChanged',

    /**
     * Determines if action on user interaction should be sent
     *
     * @default false
     * @type {boolean}
     * @name ModelsTable#sendDisplayDataChangedAction
     */
    sendDisplayDataChangedAction: false,

    /**
     * Action-name sent on change of visible columns
     *
     * @type {string}
     * @default 'columnsVisibilityChanged'
     * @name ModelsTable#columnsVisibilityChangedAction
     */
    columnsVisibilityChangedAction: 'columnsVisibilityChanged',

    /**
     * Determines if action on change of visible columns should be sent
     *
     * @default false
     * @type {boolean}
     * @name ModelsTable#sendColumnsVisibilityChangedAction
     */
    sendColumnsVisibilityChangedAction: false,

    /**
     * True if all processedColumns are hidden by <code>isHidden</code>
     *
     * @type {boolean}
     * @name ModelsTable#allColumnsAreHidden
     */
    allColumnsAreHidden: computed('processedColumns.@each.isHidden', function () {
      var processedColumns = get(this, 'processedColumns');
      return processedColumns.length > 0 && processedColumns.isEvery('isHidden', true);
    }),

    /**
     * Number of pages
     *
     * @type {number}
     * @name ModelsTable#pagesCount
     */
    pagesCount: computed('arrangedContent.[]', 'pageSize', function () {
      var pagesCount = get(this, 'arrangedContent.length') / get(this, 'pageSize');
      return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
    }),

    /**
     * List of links to the page
     * Used if <code>useNumericPagination</code> is true
     * @typedef {object} visiblePageNumber
     * @property {boolean} isLink
     * @property {boolean} isActive
     * @property {string} label
     *
     * @type {visiblePageNumber[]}
     * @name ModelsTable#visiblePageNumbers
     */
    visiblePageNumbers: computed('arrangedContentLength', 'pagesCount', 'currentPageNumber', function () {
      var _getProperties = getProperties(this, 'pagesCount', 'currentPageNumber');

      var pagesCount = _getProperties.pagesCount;
      var currentPageNumber = _getProperties.currentPageNumber;

      var notLinkLabel = '...';
      var groups = []; // array of 8 numbers
      var labels = A([]);
      groups[0] = 1;
      groups[1] = Math.min(1, pagesCount);
      groups[6] = Math.max(1, pagesCount);
      groups[7] = pagesCount;
      groups[3] = Math.max(groups[1] + 1, currentPageNumber - 1);
      groups[4] = Math.min(groups[6] - 1, currentPageNumber + 1);
      groups[2] = Math.floor((groups[1] + groups[3]) / 2);
      groups[5] = Math.floor((groups[4] + groups[6]) / 2);

      for (var n = groups[0]; n <= groups[1]; n++) {
        labels[n] = n;
      }
      var userGroup2 = groups[4] >= groups[3] && groups[3] - groups[1] > 1;
      if (userGroup2) {
        labels[groups[2]] = notLinkLabel;
      }
      for (var i = groups[3]; i <= groups[4]; i++) {
        labels[i] = i;
      }
      var userGroup5 = groups[4] >= groups[3] && groups[6] - groups[4] > 1;
      if (userGroup5) {
        labels[groups[5]] = notLinkLabel;
      }
      for (var i = groups[6]; i <= groups[7]; i++) {
        labels[i] = i;
      }
      return A(labels.compact().map(function (label) {
        return {
          label: label,
          isLink: label !== notLinkLabel,
          isActive: label === currentPageNumber };
      }));
    }),

    /**
     * Are buttons "Back" and "First" enabled
     *
     * @type {boolean}
     * @name ModelsTable#gotoBackEnabled
     */
    gotoBackEnabled: computed.gt('currentPageNumber', 1),

    /**
     * Are buttons "Next" and "Last" enabled
     *
     * @type {boolean}
     * @name ModelsTable#gotoForwardEnabled
     */
    gotoForwardEnabled: computed('currentPageNumber', 'pagesCount', function () {
      return get(this, 'currentPageNumber') < get(this, 'pagesCount');
    }),

    /**
     * @type {Ember.Object[]}
     * @name ModelsTable#filteredContent
     */
    filteredContent: computed('filterString', 'data.[]', 'useFilteringByColumns', 'processedColumns.@each.filterString', function () {
      var _getProperties2 = getProperties(this, 'processedColumns', 'data', 'useFilteringByColumns', 'filteringIgnoreCase', 'doFilteringByHiddenColumns');

      var processedColumns = _getProperties2.processedColumns;
      var data = _getProperties2.data;
      var useFilteringByColumns = _getProperties2.useFilteringByColumns;
      var filteringIgnoreCase = _getProperties2.filteringIgnoreCase;
      var doFilteringByHiddenColumns = _getProperties2.doFilteringByHiddenColumns;

      var filterString = get(this, 'filterString');

      if (!data) {
        return A([]);
      }

      var _processedColumns = processedColumns;
      if (!doFilteringByHiddenColumns) {
        _processedColumns = _processedColumns.filterBy('isHidden', false);
      }

      // global search
      var globalSearch = data.filter(function (row) {
        return _processedColumns.length ? _processedColumns.any(function (c) {
          var filterFor = get(c, 'filteredBy') || get(c, 'propertyName');
          if (filterFor) {
            var cellValue = '' + get(row, filterFor);
            if (filteringIgnoreCase) {
              cellValue = cellValue.toLowerCase();
              filterString = filterString.toLowerCase();
            }
            return -1 !== cellValue.indexOf(filterString);
          }
          return false;
        }) : true;
      });

      if (!useFilteringByColumns) {
        return A(globalSearch);
      }

      // search by each column
      return A(globalSearch.filter(function (row) {
        return _processedColumns.length ? _processedColumns.every(function (c) {
          var filterFor = get(c, 'filteredBy') || get(c, 'propertyName');
          if (filterFor) {
            var cellValue = '' + get(row, filterFor);
            if (get(c, 'useFilter')) {
              var filterString = get(c, 'filterString');
              if (get(c, 'filterWithSelect')) {
                if ('' === filterString) {
                  return true;
                }
                return 0 === compare(cellValue, filterString);
              } else {
                if (filteringIgnoreCase) {
                  cellValue = cellValue.toLowerCase();
                  filterString = filterString.toLowerCase();
                }
                return c.filterFunction(cellValue, filterString);
              }
            }
            return true;
          }
          return true;
        }) : true;
      }));
    }),

    /**
     * @type {Ember.Object[]}
     * @name ModelsTable#arrangedContent
     */
    arrangedContent: computed.sort('filteredContent', 'sortProperties'),

    /**
     * Content of the current table page
     *
     * @type {Ember.Object[]}
     * @name ModelsTable#visibleContent
     */
    visibleContent: computed('arrangedContent.[]', 'pageSize', 'currentPageNumber', function () {
      var _getProperties3 = getProperties(this, 'arrangedContent', 'pageSize', 'currentPageNumber');

      var arrangedContent = _getProperties3.arrangedContent;
      var pageSize = _getProperties3.pageSize;
      var currentPageNumber = _getProperties3.currentPageNumber;

      var startIndex = pageSize * (currentPageNumber - 1);
      if (get(arrangedContent, 'length') < pageSize) {
        return arrangedContent;
      }
      return A(arrangedContent.slice(startIndex, startIndex + pageSize));
    }),

    /**
     * Real table summary
     *
     * @type {string}
     * @name ModelsTable#summary
     */
    summary: computed('firstIndex', 'lastIndex', 'arrangedContentLength', 'messages.tableSummary', function () {
      var _getProperties4 = getProperties(this, 'arrangedContentLength', 'firstIndex', 'lastIndex');

      var arrangedContentLength = _getProperties4.arrangedContentLength;
      var firstIndex = _getProperties4.firstIndex;
      var lastIndex = _getProperties4.lastIndex;

      return (0, _emberModelsTableUtilsFmt['default'])(get(this, 'messages.tableSummary'), firstIndex, lastIndex, arrangedContentLength);
    }),

    /**
     * Is user on the last page
     *
     * @type {boolean}
     * @name ModelsTable#isLastPage
     */
    isLastPage: computed.not('gotoForwardEnabled'),

    /**
     * Alias to <code>arrangedContent.length</code>
     *
     * @type {number}
     * @name ModelsTable#arrangedContentLength
     */
    arrangedContentLength: computed.alias('arrangedContent.length'),

    /**
     * Index of the first currently shown record
     *
     * @type {number}
     * @name ModelsTable#firstIndex
     */
    firstIndex: computed('arrangedContentLength', 'pageSize', 'currentPageNumber', function () {
      var _getProperties5 = getProperties(this, 'currentPageNumber', 'pageSize', 'arrangedContentLength');

      var currentPageNumber = _getProperties5.currentPageNumber;
      var pageSize = _getProperties5.pageSize;
      var arrangedContentLength = _getProperties5.arrangedContentLength;

      return 0 === arrangedContentLength ? 0 : pageSize * (currentPageNumber - 1) + 1;
    }),

    /**
     * Index of the last shown record
     *
     * @type {number}
     * @name ModelsTable#lastIndex
     */
    lastIndex: computed('isLastPage', 'arrangedContentLength', 'currentPageNumber', 'pageSize', function () {
      var _getProperties6 = getProperties(this, 'currentPageNumber', 'pageSize', 'isLastPage', 'arrangedContentLength');

      var currentPageNumber = _getProperties6.currentPageNumber;
      var pageSize = _getProperties6.pageSize;
      var isLastPage = _getProperties6.isLastPage;
      var arrangedContentLength = _getProperties6.arrangedContentLength;

      return isLastPage ? arrangedContentLength : currentPageNumber * pageSize;
    }),

    /**
     * List of possible <code>pageSize</code> values
     * Used to change size of <code>visibleContent</code>
     *
     * @type {number[]}
     * @default [10, 25, 50]
     * @name ModelsTable#pageSizeValues
     */
    pageSizeValues: A([10, 25, 50]),

    /**
     * Show first page if for some reasons there is no content for current page, but table data exists
     *
     * @method visibleContentObserver
     * @name ModelsTable#visibleContentObserver
     */
    visibleContentObserver: function visibleContentObserver() {
      run.once(this, this.visibleContentObserverOnce);
    },

    visibleContentObserverOnce: function visibleContentObserverOnce() {
      var visibleContentLength = get(this, 'visibleContent.length');
      var dataLength = get(this, 'data.length');
      var currentPageNumber = get(this, 'currentPageNumber');
      if (!visibleContentLength && dataLength && currentPageNumber !== 1) {
        set(this, 'currentPageNumber', 1);
      }
    },

    /**
     * @method contentChangedAfterPolling
     * @name ModelsTable#contentChangedAfterPolling
     */
    contentChangedAfterPolling: function contentChangedAfterPolling() {
      get(this, 'filteredContent');
      this.notifyPropertyChange('filteredContent');
    },

    /**
     * Component init
     * Set visibility and filtering attributes for each column
     * Update messages used by table with user-provided messages (@see messages)
     * Update icons used by table with user-provided icons (@see icons)
     * Update classes used by table with user-provided css-classes (@see classes)
     *
     * @method setup
     * @name ModelsTable#setup
     */
    setup: on('init', function () {
      this._setupColumns();
      this._setupMessages();
      this._setupIcons();
      this._setupClasses();
      var self = this;
      var columnsAreUpdateable = get(this, 'columnsAreUpdateable');
      if (columnsAreUpdateable) {
        var columnFieldsToCheckUpdate = get(this, 'columnFieldsToCheckUpdate');
        assert('`columnFieldsToCheckUpdate` should be an array of strings', 'array' === typeOf(columnFieldsToCheckUpdate));
        columnFieldsToCheckUpdate.forEach(function (propertyName) {
          return self.addObserver('columns.@each.' + propertyName, self, self._setupColumnsOnce);
        });
      }
      run.next(function () {
        self.addObserver('visibleContent.length', self, self.visibleContentObserver);
      });
    }),

    /**
     * Focus on "Global filter" on component render
     *
     * @method focus
     * @name ModelsTable#focus
     */
    focus: on('didInsertElement', function () {
      if (get(this, 'showGlobalFilter') && get(this, 'focusGlobalFilter')) {
        jQ('.filterString').focus();
      }
    }),

    /**
     * Wrapper for <code>_setupColumns</code> to call it only once when observer is fired
     *
     * @method _setupColumnsOnce
     * @name ModelsTable#_setupColumnsOnce
     * @private
     */
    _setupColumnsOnce: function _setupColumnsOnce() {
      run.once(this, this._setupColumns);
    },

    /**
     * Create new properties for <code>columns</code> (filterString, useFilter, isVisible, defaultVisible)
     *
     * @method _setupColumns
     * @private
     * @name ModelsTable#_setupColumns
     */
    _setupColumns: function _setupColumns() {
      var _this = this;

      var self = this;

      var nColumns = A(get(this, 'columns').map(function (column) {
        var filterFunction = get(column, 'filterFunction');
        filterFunction = 'function' === typeOf(filterFunction) ? filterFunction : defaultFilter;

        var c = O.create(JSON.parse(JSON.stringify(column)));
        var propertyName = get(c, 'propertyName');
        var sortedBy = get(c, 'sortedBy');
        var filteredBy = get(c, 'filteredBy');
        defineProperty(c, 'cssPropertyName', computed('propertyName', function () {
          return get(this, 'propertyName').replace(/\./g, '-');
        }));
        setProperties(c, {
          filterString: get(c, 'filterString') || '',
          useFilter: !isNone(filteredBy || propertyName) && !get(c, 'disableFiltering'),
          useSorting: !isNone(sortedBy || propertyName) && !get(c, 'disableSorting')
        });

        set(c, 'filterFunction', filterFunction);

        if (isNone(get(c, 'mayBeHidden'))) {
          set(c, 'mayBeHidden', true);
        }

        var sortDirection = column.sortDirection;
        var sortPrecedence = column.sortPrecedence;

        var hasSortPrecedence = !isNone(sortPrecedence) && sortPrecedence > NOT_SORTED;
        var defaultSortPrecedence = hasSortPrecedence ? sortPrecedence : NOT_SORTED;
        var defaultSorting = sortDirection && sortPrecedence > NOT_SORTED ? sortDirection.toLowerCase() : 'none';

        defineProperty(c, 'isVisible', computed.not('isHidden'));
        defineProperty(c, 'sortAsc', computed.equal('sorting', 'asc'));
        defineProperty(c, 'sortDesc', computed.equal('sorting', 'desc'));

        setProperties(c, {
          defaultVisible: !get(c, 'isHidden'),
          sorting: defaultSorting,
          sortPrecedence: defaultSortPrecedence
        });

        if (get(c, 'filterWithSelect') && get(c, 'useFilter')) {
          var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
          if (predefinedFilterOptions && predefinedFilterOptions.length && '' !== predefinedFilterOptions[0]) {
            predefinedFilterOptions = [''].concat(predefinedFilterOptions);
          }
          var usePredefinedFilterOptions = 'array' === typeOf(predefinedFilterOptions);
          set(c, 'filterOptions', usePredefinedFilterOptions ? predefinedFilterOptions : []);
          if (!usePredefinedFilterOptions) {
            self.addObserver('data.@each.' + propertyName, self, self._updateFiltersWithSelect);
          }
        }
        return c;
      }));

      nColumns.forEach(function (column) {
        if (isNone(get(column, 'propertyName'))) {
          return;
        }
        var propertyName = get(column, 'propertyName');
        if (isNone(get(column, 'title'))) {
          set(column, 'title', self._propertyNameToTitle(propertyName));
        }
        self.addObserver('data.@each.' + propertyName, self, self.contentChangedAfterPolling);
      });
      set(this, 'processedColumns', nColumns);
      this._updateFiltersWithSelect();

      // Apply initial sorting
      set(this, 'sortProperties', A());
      var filteredOrderedColumns = nColumns.sortBy('sortPrecedence').filter(function (col) {
        return isSortedByDefault(col);
      });
      filteredOrderedColumns.forEach(function (column) {
        self.send('sort', column);
        var defaultSortedBy = column.sortedBy || column.propertyName;
        var sortingArgs = [column, defaultSortedBy, column.sortDirection.toLowerCase()];
        if (get(_this, 'multipleColumnsSorting')) {
          _this._multiColumnsSorting.apply(_this, sortingArgs);
        } else {
          _this._singleColumnSorting.apply(_this, sortingArgs);
        }
      });

      // if there is no initial sorting, try to sort by first column with `propertyName` or `sortedBy`
      // it's needed to correctly init `arrangedContent`
      var sortProperties = get(this, 'sortProperties');
      if (!sortProperties.length) {
        var column = A(get(this, 'processedColumns')).find(function (column) {
          return get(column, 'sortedBy') || get(column, 'propertyName');
        });
        if (column) {
          this.send('sort', column);
        }
      }
    },

    /**
     * Convert some string to the human readable one
     *
     * @method _propertyNameToTitle
     * @param {string} name value to convert
     * @return {string}
     * @private
     * @name ModelsTable#_propertyNameToTitle
     */
    _propertyNameToTitle: function _propertyNameToTitle(name) {
      return S.capitalize(S.dasherize(name).replace(/\-/g, ' '));
    },

    /**
     * Update messages used by widget with custom values provided by user in the <code>customMessages</code>
     *
     * @method _setupMessages
     * @private
     * @name ModelsTable#_setupMessages
     */
    _setupMessages: observer('customMessages', function () {
      var customIcons = getWithDefault(this, 'customMessages', {});
      var newMessages = smartExtend(customIcons, defaultMessages);
      set(this, 'messages', O.create(newMessages));
    }),

    /**
     * Update icons-classes used by widget with custom values provided by user in the <code>customIcons</code>
     *
     * @method _setupIcons
     * @private
     * @name ModelsTable#_setupIcons
     */
    _setupIcons: function _setupIcons() {
      var customIcons = getWithDefault(this, 'customIcons', {});
      var newIcons = smartExtend(customIcons, defaultIcons);
      set(this, 'icons', O.create(newIcons));
    },

    /**
     * Update css-classes used by widget with custom values provided by user in the <code>customClasses</code>
     *
     * @method _setupClasses
     * @private
     * @name ModelsTable#_setupClasses
     */
    _setupClasses: function _setupClasses() {
      var customClasses = getWithDefault(this, 'customClasses', {});
      var newClasses = smartExtend(customClasses, defaultCssClasses);
      set(this, 'classes', O.create(newClasses));
    },

    /**
     * Updates <code>filterOptions</code> for columns which use <code>filterWithSelect</code>
     * and don't have <code>predefinedFilterOptions</code>
     * <code>filterOptions</code> are calculated like <code>data.mapBy(column.propertyName).uniq()</code>,
     * where data is component's <code>data</code>
     * If preselected option doesn't exist after <code>filterOptions</code> update,
     * <code>filterString</code> is reverted to empty string (basically, filtering for this column is dropped)
     *
     * @private
     * @name ModelsTable#_updateFiltersWithSelect
     */
    _updateFiltersWithSelect: function _updateFiltersWithSelect() {
      var _this2 = this;

      var processedColumns = get(this, 'processedColumns');
      var data = get(this, 'data');
      processedColumns.forEach(function (column) {
        var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
        var filterWithSelect = get(column, 'filterWithSelect');
        if (filterWithSelect && 'array' !== typeOf(predefinedFilterOptions)) {
          var propertyName = get(column, 'propertyName');
          var cssPropertyName = get(column, 'cssPropertyName');
          var filterOptions = [''].concat(A(A(data.filterBy(propertyName)).mapBy(propertyName)).uniq());
          var filterString = get(column, 'filterString');
          if (-1 === filterOptions.indexOf(filterString)) {
            _this2.$('.changeFilterForColumn.' + cssPropertyName).val(''); // select empty value
            set(column, 'filterString', '');
          }
          set(column, 'filterOptions', filterOptions);
        }
      });
    },

    /**
     * Set <code>sortProperties</code> when single-column sorting is used
     *
     * @param {ModelsTable~ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _singleColumnSorting
     * @private
     * @name ModelsTable#_singleColumnSorting
     */
    _singleColumnSorting: function _singleColumnSorting(column, sortedBy, newSorting) {
      get(this, 'processedColumns').setEach('sorting', 'none');
      set(column, 'sorting', newSorting);
      set(this, 'sortProperties', 'none' === newSorting ? [] : [sortedBy + ':' + newSorting]);
    },

    /**
     * Set <code>sortProperties</code> when multi-columns sorting is used
     *
     * @param {ModelsTable~ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _multiColumnsSorting
     * @private
     * @name ModelsTable#_multiColumnsSorting
     */
    _multiColumnsSorting: function _multiColumnsSorting(column, sortedBy, newSorting) {
      set(column, 'sorting', newSorting);
      var sortProperties = get(this, 'sortProperties');
      var sortPropertiesMap = {};
      sortProperties.forEach(function (p) {
        var _p$split = p.split(':');

        var _p$split2 = _slicedToArray(_p$split, 2);

        var propertyName = _p$split2[0];
        var order = _p$split2[1];

        sortPropertiesMap[propertyName] = order;
      });
      delete sortPropertiesMap[sortedBy];

      var newSortProperties = A([]);
      keys(sortPropertiesMap).forEach(function (propertyName) {
        if (propertyName !== sortedBy) {
          newSortProperties.pushObject(propertyName + ':' + sortPropertiesMap[propertyName]);
        }
      });
      if ('none' !== newSorting) {
        newSortProperties.pushObject(sortedBy + ':' + newSorting);
      }
      set(this, 'sortProperties', newSortProperties);
    },

    /**
     * send <code>displayDataChangedAction</code>-action when user does sort of filter
     * action is sent only if <code>sendDisplayDataChangedAction</code> is true (default false)
     *
     * @name ModelsTable#userInteractionObserver
     */
    _sendDisplayDataChangedAction: function _sendDisplayDataChangedAction() {
      var _this3 = this;

      if (get(this, 'sendDisplayDataChangedAction')) {
        (function () {
          var columns = get(_this3, 'processedColumns');
          var settings = O.create({
            sort: get(_this3, 'sortProperties'),
            currentPageNumber: get(_this3, 'currentPageNumber'),
            pageSize: get(_this3, 'pageSize'),
            filterString: get(_this3, 'filterString'),
            filteredContent: get(_this3, 'filteredContent'),
            columnFilters: {}
          });
          columns.forEach(function (column) {
            if (get(column, 'filterString')) {
              settings.columnFilters[get(column, 'propertyName')] = get(column, 'filterString');
            }
          });
          _this3.sendAction('displayDataChangedAction', settings);
        })();
      }
    },

    /**
     * send <code>columnsVisibilityChangedAction</code>-action when user changes which columns are visible
     * action is sent only if <code>sendColumnsVisibilityChangedAction</code> is true (default false)
     */
    _sendColumnsVisibilityChangedAction: function _sendColumnsVisibilityChangedAction() {
      if (get(this, 'sendColumnsVisibilityChangedAction')) {
        var columns = get(this, 'processedColumns');
        var columnsVisibility = columns.map(function (column) {
          var options = getProperties(column, 'isHidden', 'mayBeHidden', 'propertyName');
          options.isHidden = !!options.isHidden;
          return options;
        });
        this.sendAction('columnsVisibilityChangedAction', columnsVisibility);
      }
    },

    /**
     * Force <code>arrangedContent</code> to be updated when <code>sortProperties</code> is changed
     * Currently "normal" <code>Em.computed.sort</code> has issue when sort properties is empty
     *
     * @method forceUpdateArrangedContent
     * @name ModelsTable#forseUpdateArrangedContent
     * @private
     */
    forceUpdateArrangedContent: observer('filteredContent.[]', 'sortProperties.[]', function () {
      this.notifyPropertyChange('arrangedContent');
    }),

    actions: {

      sendAction: function sendAction() {
        this.sendAction.apply(this, arguments);
      },

      /**
       * @param {ModelsTable~ModelsTableColumn} column
       */
      toggleHidden: function toggleHidden(column) {
        if (get(column, 'mayBeHidden')) {
          column.toggleProperty('isHidden');
          this._sendColumnsVisibilityChangedAction();
        }
      },

      showAllColumns: function showAllColumns() {
        get(this, 'processedColumns').setEach('isHidden', false);
        this._sendColumnsVisibilityChangedAction();
      },

      hideAllColumns: function hideAllColumns() {
        get(this, 'processedColumns').setEach('isHidden', true);
        this._sendColumnsVisibilityChangedAction();
      },

      restoreDefaultVisibility: function restoreDefaultVisibility() {
        var _this4 = this;

        get(this, 'processedColumns').forEach(function (c) {
          set(c, 'isHidden', !get(c, 'defaultVisible'));
          _this4._sendColumnsVisibilityChangedAction();
        });
      },

      gotoFirst: function gotoFirst() {
        if (!get(this, 'gotoBackEnabled')) {
          return;
        }
        set(this, 'currentPageNumber', 1);
        this._sendDisplayDataChangedAction();
      },

      gotoPrev: function gotoPrev() {
        if (!get(this, 'gotoBackEnabled')) {
          return;
        }
        if (get(this, 'currentPageNumber') > 1) {
          this.decrementProperty('currentPageNumber');
          this._sendDisplayDataChangedAction();
        }
      },

      gotoNext: function gotoNext() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }
        var currentPageNumber = get(this, 'currentPageNumber');
        var pageSize = get(this, 'pageSize');
        var arrangedContentLength = get(this, 'arrangedContent.length');
        if (arrangedContentLength > pageSize * (currentPageNumber - 1)) {
          this.incrementProperty('currentPageNumber');
          this._sendDisplayDataChangedAction();
        }
      },

      gotoLast: function gotoLast() {
        if (!get(this, 'gotoForwardEnabled')) {
          return;
        }
        var pageSize = get(this, 'pageSize');
        var arrangedContentLength = get(this, 'arrangedContent.length');
        var pageNumber = arrangedContentLength / pageSize;
        pageNumber = 0 === pageNumber % 1 ? pageNumber : Math.floor(pageNumber) + 1;
        set(this, 'currentPageNumber', pageNumber);
        this._sendDisplayDataChangedAction();
      },

      gotoCustomPage: function gotoCustomPage(pageNumber) {
        set(this, 'currentPageNumber', pageNumber);
        this._sendDisplayDataChangedAction();
      },

      /**
       * @param {ModelsTable~ModelsTableColumn} column
       */
      sort: function sort(column) {
        var sortMap = {
          none: 'asc',
          asc: 'desc',
          desc: 'none'
        };
        var sortedBy = get(column, 'sortedBy') || get(column, 'propertyName');
        if (isNone(sortedBy)) {
          return;
        }
        var currentSorting = get(column, 'sorting');
        var newSorting = sortMap[currentSorting.toLowerCase()];
        var sortingArgs = [column, sortedBy, newSorting];
        if (get(this, 'multipleColumnsSorting')) {
          this._multiColumnsSorting.apply(this, sortingArgs);
        } else {
          this._singleColumnSorting.apply(this, sortingArgs);
        }
        set(this, 'currentPageNumber', 1);
        this._sendDisplayDataChangedAction();
      },

      changePageSize: function changePageSize() {
        var selectedIndex = this.$('.changePageSize')[0].selectedIndex;
        var pageSizeValues = get(this, 'pageSizeValues');
        var selectedValue = pageSizeValues[selectedIndex];
        set(this, 'pageSize', selectedValue);
        set(this, 'currentPageNumber', 1);
        this._sendDisplayDataChangedAction();
      },

      /**
       * @param {ModelsTable~ModelsTableColumn} column
       */
      changeFilterForColumn: function changeFilterForColumn(column) {
        var cssPropertyName = get(column, 'cssPropertyName');
        var val = this.$('.changeFilterForColumn.' + cssPropertyName)[0].value;
        set(column, 'filterString', val);
        set(this, 'currentPageNumber', 1);
        this._sendDisplayDataChangedAction();
      },

      changeFilterString: function changeFilterString() {
        set(this, 'currentPageNumber', 1);
        this._sendDisplayDataChangedAction();
      }

    }

  });
});