define('ember-crumbly/components/bread-crumb', ['exports', 'ember', 'ember-crumbly/templates/components/bread-crumb'], function (exports, _ember, _emberCrumblyTemplatesComponentsBreadCrumb) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var oneWay = computed.oneWay;
  var bool = computed.bool;
  exports['default'] = Component.extend({
    layout: _emberCrumblyTemplatesComponentsBreadCrumb['default'],
    tagName: 'li',
    classNameBindings: ['crumbClass'],

    crumbClass: oneWay('breadCrumbs.crumbClass'),
    linkClass: oneWay('breadCrumbs.linkClass'),
    hasBlock: bool('template').readOnly()
  });
});