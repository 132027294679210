define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zSJbQzMm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"li\"],[9,\"class\",\"ember-power-select-group\"],[10,\"aria-disabled\",[25,\"ember-power-select-true-string-if-present\",[[20,[\"disabled\"]]],null],null],[9,\"role\",\"option\"],[7],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"ember-power-select-group-name\"],[7],[1,[18,\"groupName\"],false],[8],[0,\"\\n  \"],[11,1],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
    }
  });

  _exports.default = _default;
});