define('ember-rapid-forms/components/em-select', ['exports', 'ember-rapid-forms/templates/components/em-select', 'ember-rapid-forms/mixins/input-component'], function (exports, _emSelect, _inputComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputComponent.default, {
    layout: _emSelect.default,
    validationIcons: false,
    propertyIsModel: false,
    property: null,
    content: null,
    elementClass: null,
    selection: null,
    optionValuePath: 'id',
    optionLabelPath: 'value',
    optionDisabledPath: null,
    prompt: null,
    disabled: null,
    autofocus: null,
    size: 0,
    optionGroupLabelPath: null,
    optionGroupContentPath: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run(this, function () {
        if (_this.get('model.isLoading')) {
          _this.get('model').on('didLoad', function () {
            _this._setValue();
          });
        } else {
          _this._setValue();
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._addComputedSelectedValue();
    },


    actions: {
      change: function change() {
        this._setValue();

        var changeAction = this.get('action');
        var model = this.get('model');
        var selectedID = model.get(this.get('property'));

        if (model && changeAction) {
          changeAction(selectedID);
        }
      }
    },

    _addComputedSelectedValue: function _addComputedSelectedValue() {
      var content = this.get('content');

      if (!content) {
        this.set('content', []);
      }

      // set it to the correct value of the selection
      Ember.defineProperty(this, 'selectedValue', Ember.computed('model.' + this.get('property'), function () {
        var _this2 = this;

        if (this.get("multiple")) {
          var values = this.get('model.' + this.get('property'));

          if (values && values.map) {
            return values.map(function (value) {
              return _this2._getValue(value);
            });
          } else {
            return [];
          }
        } else {
          return this._getValue(this.get('model.' + this.get('property')));
        }
      }));
    },
    _getValue: function _getValue(value) {
      var propertyIsModel = this.get('propertyIsModel');
      if (propertyIsModel && value !== null && value !== undefined) {
        var optionValuePath = this.get('optionValuePath');
        if (value.get === undefined) {
          value = value[optionValuePath];
        } else {
          value = value.get(optionValuePath);
        }
      }
      return value;
    },
    _setValue: function _setValue() {
      if (this.get("multiple")) {
        this._setValueMultiple();
      } else {
        this._setValueSingle();
      }
    },
    _setValueSingle: function _setValueSingle() {
      var selectedEl = this.$('select')[0];
      var model = this.get('model');
      if (model) {

        var selectedIndex = selectedEl.selectedIndex;

        if (selectedIndex < 0 || !this.get('content.length')) return;

        // check whether we show prompt the correct to show index is one less
        // when selecting prompt don't change anything
        if (this.get('prompt')) {
          if (selectedIndex !== 0) {
            selectedIndex--;
          } else {
            if (this.get('property')) {
              model.set(this.get('property'), null);
            }
            return;
          }
        }

        var selectedID = void 0,
            selectedValue = void 0;

        if (this.get('optionGroupContentPath')) {
          var selectedElement = selectedEl.options[selectedIndex + 1];
          var optGroup = selectedElement.parentNode;
          var optGroupOptions = optGroup.children;
          var positionInOptGroup = Array.prototype.indexOf.call(optGroupOptions, selectedElement);
          var optionGroup = this.get('content').filterBy(this.get('optionGroupLabelPath'), optGroup.label)[0];

          selectedValue = Ember.get(optionGroup, this.get('optionGroupContentPath')).objectAt(positionInOptGroup);
        } else {
          var content = this.get('content');
          selectedValue = content.objectAt(selectedIndex);
        }

        if (this.get('optionDisabledPath') && Ember.get(selectedValue, this.get('optionDisabledPath'))) {
          // if it is disabled don't do anything
          return;
        }

        var optionValuePath = this.get('optionValuePath');
        var propertyIsModel = this.get('propertyIsModel');

        if (propertyIsModel) {
          selectedID = selectedValue;
        } else {
          selectedID = selectedValue[optionValuePath];
        }

        model.set(this.get('property'), selectedID);
      }
    },
    _setValueMultiple: function _setValueMultiple() {
      var _this3 = this;

      var selectedEl = this.$('select')[0];
      var model = this.get('model');
      if (model) {

        var selectedIndices = [];

        // el.selectedOptions is not supported in IE.
        for (var index = 0; index < selectedEl.options.length; index++) {
          if (selectedEl.options[index].selected) {
            selectedIndices.push(index);
          }
        }

        if (selectedIndices.length === 0 || !this.get('content.length')) return;

        // check whether we show prompt the correct to show index is one less
        // if we select prompt, remove it. If we're empty, then step model to empty array.
        if (this.get('prompt')) {
          var promptIndex = Array.prototype.indexOf.call(selectedIndices, 0);
          if (promptIndex > -1) {
            selectedIndices.splice(promptIndex, 1);
          }

          if (selectedIndices.length === 0) {
            model.set(this.get('property'), []);
          }
          return;
        }

        var selectedValues = [];

        if (this.get('optionGroupContentPath')) {
          selectedValues = selectedIndices.flatMap(function (selectedIndex) {
            var selectedElement = selectedEl.options[selectedIndex + 1];
            var optGroup = selectedElement.parentNode;
            var optGroupOptions = optGroup.children;
            var positionInOptGroup = Array.prototype.indexOf.call(optGroupOptions, selectedElement);
            var optionGroup = _this3.get('content').filterBy(_this3.get('optionGroupLabelPath'), optGroup.label)[0];

            var selectedValue = Ember.get(optionGroup, _this3.get('optionGroupContentPath')).objectAt(positionInOptGroup);

            if (_this3.get('optionDisabledPath') && Ember.get(selectedValue, _this3.get('optionDisabledPath'))) {
              // remove this item if it is disabled
              return [];
            } else {
              return [selectedValue];
            }
          });
        } else {
          var content = this.get('content');

          selectedValues = selectedIndices.map(function (selectedIndex) {
            return content.objectAt(selectedIndex);
          });
        }

        var optionValuePath = this.get('optionValuePath');
        var propertyIsModel = this.get('propertyIsModel');

        var finalSelection = void 0;

        if (propertyIsModel) {
          finalSelection = selectedValues;
        } else {
          finalSelection = selectedValues.map(function (value) {
            return value[optionValuePath];
          });
        }

        model.set(this.get('property'), finalSelection);
      }
    }
  });
});