define('ember-rapid-forms/components/em-form-label', ['exports', 'ember-rapid-forms/templates/components/em-form-label', 'ember-rapid-forms/mixins/has-class-calc'], function (exports, _emFormLabel, _hasClassCalc) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_hasClassCalc.default, {
        layout: _emFormLabel.default,
        tagName: 'label',
        classNames: ['control-label'],
        classNameBindings: ['extraClass', 'inlineClassCalc', 'horiClassCalc'],
        attributeBindings: ['for'],
        horiClass: 'col-sm-2',
        inlineClass: 'sr-only'
    });
});