define('ember-cli-d3/system/stage', ['exports', 'ember', 'd3', 'ember-cli-d3/system/selection-proxy', 'ember-cli-d3/system/canvas-proxy'], function (exports, _ember, _d3, _emberCliD3SystemSelectionProxy, _emberCliD3SystemCanvasProxy) {

  var Stage = _ember['default'].Object.extend({
    svg: _ember['default'].computed('element', function () {
      var div = this.get('element');
      var element = document.createElementNS(_d3['default'].ns.prefix.svg, 'svg');

      element.appendChild(document.createComment('defs'));

      _d3['default'].select(element).style('width', '100%').style('height', '100%');

      Stage.replace(div, 'svg', element);

      return _emberCliD3SystemSelectionProxy['default'].create({ element: element });
    }).readOnly(),

    canvas: _ember['default'].computed('element', function () {
      var div = this.get('element');
      var element = document.createElement('canvas');

      _d3['default'].select(element).style('width', '100%').style('height', '100%');

      Stage.replace(div, 'canvas', element);

      return _emberCliD3SystemCanvasProxy['default'].create({
        element: element,
        context: element.getContext('2d')
      });
    }).readOnly()
    //webgl: Ember.computed('element', function () {
    //  var div = this.get('element');
    //  var element = document.createElement('canvas');

    //  element.setAttribute('width', '100%');
    //  element.setAttribute('height', '100%');

    //  Stage.replace(div, 'canvas', element);

    //  return canvas.getContext('experimental-webgl')
    //      || canvas.getContext('webgl');
    //}).readOnly()

  });

  Stage.reopenClass({
    stages: [],

    replace: function replace(parent, target, replacement) {
      var nodes = parent.childNodes;
      var len = nodes.length;
      var node;

      while (node = nodes.item(--len)) {

        if (node.nodeType === Node.COMMENT_NODE && node.textContent === target) {
          break;
        }
      }

      _ember['default'].run.schedule('render', function () {
        parent.replaceChild(replacement, node);
      });
    }
  });

  //Stage.stages[1597] = 'webgl';
  Stage.stages[2584] = 'canvas';
  Stage.stages[4181] = 'svg';
  //Stage.stages[6765] = 'html';

  exports['default'] = Stage;
});