define('ember-rapid-forms/components/em-form-control-help', ['exports', 'ember-rapid-forms/templates/components/em-form-control-help', 'ember-rapid-forms/mixins/has-class-calc'], function (exports, _emFormControlHelp, _hasClassCalc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hasClassCalc.default, {
    layout: _emFormControlHelp.default,
    tagName: 'span',
    classNames: ['help-block'],
    classNameBindings: ['extraClass', 'horiClassCalc'],
    text: null,
    extraClass: null,
    horiClass: 'col-sm-offset-2 col-sm-10',

    errors: Ember.computed.alias('formComponent.errors'),
    hasError: Ember.computed.alias('formComponent.hasError')
  });
});