define('ember-data-autoreload/index', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var Mixin = _ember['default'].Mixin;
  var assert = _ember['default'].assert;
  var resolve = _ember['default'].RSVP.resolve;
  var isArray = _ember['default'].isArray;
  exports['default'] = Mixin.create({
    /* overridable properties/methods */
    autoReloadStrategy: 'incremental', // can be 'incremental' or 'fixed'

    autoReloadDelay: 30000,

    autoReloadMaximumDelay: 300000,
    autoReloadDelayGrowth: 2,

    willAutoReload: function willAutoReload() /* currentSnapshot */{},
    didAutoReload: function didAutoReload() /* attributesChanged, newSnapshot, oldSnapshot */{},

    shouldAutoReload: function shouldAutoReload() /* currentSnapshot */{
      return true;
    },

    /* end overridable properties/methods */

    /* public api */

    startAutoReloading: function startAutoReloading() {
      this.get('_autoReloadTask').perform();
    },

    stopAutoReloading: function stopAutoReloading() {
      this.get('_autoReloadTask').cancelAll();
    },

    /* end public api */

    unloadRecord: function unloadRecord() {
      this._super.apply(this, arguments);

      this.get('_autoReloadTask').cancelAll();
    },

    _autoReloadTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var nextDelay, oldState, shouldReload, newState, changeOccured;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            nextDelay = this._getNextReloadDelay(this.get('autoReloadDelay'), true);

          case 1:
            if (!true) {
              context$1$0.next = 25;
              break;
            }

            context$1$0.next = 4;
            return (0, _emberConcurrency.timeout)(nextDelay);

          case 4:
            oldState = this._createSnapshot();

            if (!(this.get('isLoaded') && !this.get('isDeleted'))) {
              context$1$0.next = 22;
              break;
            }

            context$1$0.next = 8;
            return resolve(this.shouldAutoReload(oldState));

          case 8:
            shouldReload = context$1$0.sent;

            if (shouldReload) {
              context$1$0.next = 11;
              break;
            }

            return context$1$0.abrupt('continue', 1);

          case 11:
            context$1$0.next = 13;
            return resolve(this.willAutoReload(oldState));

          case 13:
            context$1$0.next = 15;
            return this.reload();

          case 15:
            newState = this._createSnapshot();
            changeOccured = this._didAttributesChange(oldState.attributes(), newState.attributes());
            context$1$0.next = 19;
            return resolve(this.didAutoReload(changeOccured, newState, oldState));

          case 19:

            nextDelay = this._getNextReloadDelay(nextDelay, changeOccured);
            context$1$0.next = 23;
            break;

          case 22:
            return context$1$0.abrupt('break', 25);

          case 23:
            context$1$0.next = 1;
            break;

          case 25:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).drop(),

    _getNextReloadDelay: function _getNextReloadDelay(currentDelay, attributesChanged) {
      var strategy = this.get('autoReloadStrategy');
      switch (strategy) {
        case 'fixed':
          return this.get('autoReloadDelay');
        case 'incremental':
          return attributesChanged ? this.get('autoReloadDelay') : Math.min(this.get('autoReloadMaximumDelay'), currentDelay * this.get('autoReloadDelayGrowth'));
        default:
          assert('`autoReloadStrategy` must be either "fixed" or "incremental", you specified "' + strategy + '"');
      }
    },

    _didAttributesChange: function _didAttributesChange(oldAttrs, newAttrs) {
      for (var key in oldAttrs) {
        if (isArray(oldAttrs[key] && isArray[newAttrs[key]])) {
          if (oldAttrs[key].length === newAttrs[key].length) {
            var allItemsSame = true;
            for (var i = 0; i < oldAttrs[key].length; i++) {
              if (this._didAttributesChange(oldAttrs[key][i], newAttrs[key][i])) {
                allItemsSame = false;
                break;
              }
            }

            if (!allItemsSame) {
              return true;
            }
          }

          return true;
        } else if (oldAttrs[key] && typeof oldAttrs[key] === 'object' && newAttrs[key] && typeof newAttrs[key] === 'object') {

          // recursively go through the hash
          if (this._didAttributesChange(oldAttrs[key], newAttrs[key])) {
            return true;
          }
        } else if (oldAttrs[key] !== newAttrs[key]) {
          return true;
        }
      }

      return false;
    }
  });
});