define('ember-cli-d3/utils/lodash', ['exports'], function (exports) {
  exports.identity = identity;
  exports.flow = flow;
  exports.scan = scan;
  var slice = Array.prototype.slice;

  exports.slice = slice;
  var concat = Array.prototype.concat;

  exports.concat = concat;
  // Create (and cache) an identity function that returns the
  // argument at specified index.

  function identity() {
    var index = arguments.length <= 0 || arguments[0] === undefined ? 0 : arguments[0];

    return identity[index] || (identity[index] = function () {
      return arguments[index];
    });
  }

  // like compose, but from left to right

  function flow() {
    for (var _len = arguments.length, fns = Array(_len), _key = 0; _key < _len; _key++) {
      fns[_key] = arguments[_key];
    }

    return function () {
      var _this = this;

      return fns.reduce(function (result, fn) {
        if (typeof fn === 'function') {
          fn = fn.apply(_this, result);
        }

        return [fn];
      }, arguments);
    };
  }

  function scan(col, fn, init) {
    var ret = [];

    if (arguments.length === 3) {
      col.reduce(function (prev, item, index, arr) {
        return ret[index] = fn(prev, item, index, arr);
      }, init);
    } else {
      col.reduce(function (prev, item, index, arr) {
        return ret[index] = fn(prev, item, index, arr);
      });
    }

    return ret;
  }
});