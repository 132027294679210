define("ember-modal-dialog/templates/components/deprecated-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "63vhub+v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[20,[\"destinationElementId\"]],[20,[\"renderInPlace\"]]]],{\"statements\":[[4,\"if\",[[20,[\"hasOverlay\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[18,\"overlayClassNamesString\"],null],[10,\"onclick\",[25,\"action\",[[19,0,[]],[25,\"action\",[[19,0,[]],\"onClickOverlay\"],null]],null],null],[9,\"tabindex\",\"-1\"],[9,\"data-emd-overlay\",\"\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[20,[\"renderInPlace\"]]],null,{\"statements\":[[4,\"ember-modal-dialog-positioned-container\",null,[[\"class\",\"targetAttachment\",\"target\",\"renderInPlace\"],[[20,[\"containerClassNamesString\"]],[20,[\"targetAttachment\"]],[20,[\"target\"]],[20,[\"renderInPlace\"]]]],{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"ember-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\"],[[20,[\"containerClassNamesString\"]],[20,[\"target\"]],[20,[\"attachment\"]],[20,[\"targetAttachment\"]],[20,[\"targetModifier\"]],[20,[\"tetherClassPrefix\"]],[20,[\"offset\"]],[20,[\"targetOffset\"]],[20,[\"constraints\"]]]],{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/deprecated-tether-dialog.hbs"
    }
  });

  _exports.default = _default;
});