define('ember-rapid-forms/components/em-form-submit', ['exports', 'ember-rapid-forms/templates/components/em-form-submit'], function (exports, _emFormSubmit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emFormSubmit.default,
    classes: 'btn btn-default',
    classNames: ['form-group'],
    text: 'Submit',
    type: 'submit',
    horiClass: 'col-sm-offset-2 col-sm-10',
    disabled: Ember.computed('model.isValid', {
      get: function get() {
        if (this.get('form.showErrorsOnSubmit') && !this.get('form.isSubmitted')) {
          return false;
        }

        if (!Ember.isNone(this.get('model.isValid'))) {
          return !this.get('model.isValid');
        } else {
          return false;
        }
      }
    }),

    actions: {
      submit: function submit() {
        this.sendAction('submit');
      }
    }
  });
});