define('ember-rapid-forms/components/em-checkbox-input', ['exports', 'ember-rapid-forms/templates/components/em-checkbox-input'], function (exports, _emCheckboxInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emCheckboxInput.default,
    tagName: '',
    label: Ember.computed.alias('checkboxComponent.label'),
    form: Ember.computed.alias('checkboxComponent.form'),
    inputId: Ember.computed.alias('checkboxComponent.inputId'),
    labelClass: Ember.computed.alias('checkboxComponent.labelClass'),
    model: Ember.computed.alias('checkboxComponent.model'),
    property: Ember.computed.alias('checkboxComponent.property'),
    disabled: Ember.computed.alias('checkboxComponent.disabled'),
    required: Ember.computed.alias('checkboxComponent.required'),
    autofocus: Ember.computed.alias('checkboxComponent.autofocus'),
    readonly: Ember.computed.alias('checkboxComponent.readonly'),
    title: Ember.computed.alias('checkboxComponent.title'),
    elementClass: Ember.computed.alias('checkboxComponent.elementClass'),
    name: Ember.computed.alias('checkboxComponent.name')
  });
});