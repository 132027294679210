define("ember-rapid-forms/templates/components/em-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BgjD5AUd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"em-form-group\",null,[[\"model\",\"property\",\"inputComponent\",\"groupClass\"],[[20,[\"model\"]],[20,[\"property\"]],[19,0,[]],[20,[\"groupClass\"]]]],{\"statements\":[[4,\"if\",[[20,[\"checkboxWrapper\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[10,\"class\",[18,\"checkboxWrapper\"],null],[7],[0,\"\\n            \"],[1,[25,\"em-checkbox-input\",null,[[\"checkboxComponent\"],[[19,0,[]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"em-checkbox-input\",null,[[\"checkboxComponent\"],[[19,0,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/em-checkbox.hbs"
    }
  });

  _exports.default = _default;
});