define('ember-screen/services/screen', ['exports', 'ember-screen/window/browser', 'ember-screen/window/null'], function (exports, _browser, _null) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isFastBoot = typeof FastBoot !== 'undefined'; /* globals FastBoot */

  var WindowClass = isFastBoot ? _null.default : _browser.default;

  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.win = new WindowClass();
      this.win.onSizeUpdate(this.handleResize.bind(this));
    },
    willDestroy: function willDestroy() {
      this.win.teardown();
    },
    handleResize: function handleResize(_ref) {
      var width = _ref.width,
          height = _ref.height;

      this.set('width', width);
      this.set('height', height);
    },
    stubMediaFeatures: function stubMediaFeatures(features) {
      this.win.stubMediaFeatures(features);
    }
  });
});