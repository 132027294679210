define("ember-rapid-forms/templates/components/em-form-submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NLRSZVOw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"form\",\"isHorizontal\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",[18,\"horiClass\"],null],[7],[0,\"\\n        \"],[6,\"button\"],[10,\"class\",[18,\"classes\"],null],[10,\"type\",[18,\"type\"],null],[10,\"disabled\",[18,\"disabled\"],null],[3,\"action\",[[19,0,[]],\"submit\"]],[7],[1,[18,\"text\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"button\"],[10,\"class\",[18,\"classes\"],null],[10,\"type\",[18,\"type\"],null],[10,\"disabled\",[18,\"disabled\"],null],[3,\"action\",[[19,0,[]],\"submit\"]],[7],[1,[18,\"text\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-rapid-forms/templates/components/em-form-submit.hbs"
    }
  });

  _exports.default = _default;
});