define('ember-cli-d3/mixins/margin-convention', ['exports', 'ember', 'ember-cli-d3/utils/css', 'ember-cli-d3/utils/version'], function (exports, _ember, _emberCliD3UtilsCss, _emberCliD3UtilsVersion) {
  exports['default'] = _ember['default'].Mixin.create({
    defaultMargin: 20,
    margin: (0, _emberCliD3UtilsVersion.computed)('defaultMargin', function (name, newValue) {
      if (arguments.length === 1) {
        newValue = this.get('defaultMargin');
      }

      return (0, _emberCliD3UtilsCss.box)(newValue);
    }),

    width: null,
    contentWidth: (0, _emberCliD3UtilsVersion.computed)('width', 'margin.{left,right}', function () {
      return this.get('width') - this.get('margin.left') - this.get('margin.right');
    }).readOnly(),

    height: null,
    contentHeight: (0, _emberCliD3UtilsVersion.computed)('height', 'margin.{top,bottom}', function () {
      return this.get('height') - this.get('margin.top') - this.get('margin.bottom');
    }).readOnly(),

    call: function call(selection) {
      selection.attr('transform', 'translate(' + this.get('margin.left') + ' ' + this.get('margin.top') + ')');
    }

  });
});