define("ember-date-components/utils/set-time-on-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setTimeOnDate = setTimeOnDate;
  _exports.default = void 0;

  function setTimeOnDate(date, time) {
    var hours = time ? time.hours() : 0;
    var minutes = time ? time.minutes() : 0;
    var newDate = date.clone().startOf('day').hours(hours).minutes(minutes);
    return newDate;
  }

  var _default = setTimeOnDate;
  _exports.default = _default;
});