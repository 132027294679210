define("ember-date-components/templates/components/date-picker-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "siesQ0dx",
    "block": "{\"symbols\":[],\"statements\":[[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"date-picker__header__button date-picker__header__button--previous\"],[9,\"data-test-date-picker-goto-previous-month\",\"\"],[3,\"action\",[[19,0,[]],\"gotoPreviousMonth\"]],[7],[0,\"\\n  <\\n\"],[8],[0,\"\\n\\n\"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"date-picker__header__button date-picker__header__button--next\"],[9,\"data-test-date-picker-goto-next-month\",\"\"],[3,\"action\",[[19,0,[]],\"gotoNextMonth\"]],[7],[0,\"\\n  >\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"date-picker__header__title\"],[10,\"data-test-date-picker-month\",[25,\"moment-format\",[[20,[\"currentMonth\"]],\"MM\"],null],null],[10,\"data-test-date-picker-year\",[25,\"moment-format\",[[20,[\"currentMonth\"]],\"YYYY\"],null],null],[7],[0,\"\\n\\n  \"],[1,[25,\"date-picker-month-year-select\",null,[[\"minDate\",\"maxDate\",\"currentMonth\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"gotoMonth\"],[[20,[\"minDate\"]],[20,[\"maxDate\"]],[20,[\"currentMonth\"]],[20,[\"disableMonthPicker\"]],[20,[\"disableYearPicker\"]],[20,[\"availableYearOffset\"]],[25,\"action\",[[19,0,[]],\"gotoMonth\"],null]]]],false],[0,\"\\n\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-picker-navigation.hbs"
    }
  });

  _exports.default = _default;
});