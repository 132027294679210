define('ember-rapid-forms/components/em-custom-input', ['exports', 'ember-rapid-forms/mixins/has-id', 'ember-rapid-forms/templates/components/em-custom-input'], function (exports, _hasId, _emCustomInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hasId.default, {
    layout: _emCustomInput.default,
    elementClass: null,
    property: null,
    label: null,
    name: null,
    placeholder: null,
    autofocus: null,
    disabled: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('id', this.get('inputId'));
      this.set('class', this.get('elementClass'));
    }
  });
});